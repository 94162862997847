import React from 'react';
import { Link } from 'react-router-dom';

function ProductCard({ product }) {
  return (
    <Link 
      to={`/product/${product.id}`} 
      className={`group text-sm relative ${product.stock === 0 ? 'opacity-50' : ''}`}
    >
      {/* Product Image */}
      <div className="aspect-square w-full overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75 relative">
        <img
          alt={product.productName}
          src={product.coverImageUrl}
          className="h-full w-full object-cover object-center"
        />

        {/* Badge Container */}
        <div className="absolute top-2 left-2 space-y-1">
          {/* Discount Percentage Badge */}
          {product.discount > 0 && (
            <div className="bg-green-500 text-white text-xs font-bold px-2 py-1 rounded">
              %{Math.round(product.discount)} İndirim
            </div>
          )}

          {/* Free Shipping Badge */}
          {product.price > 1750 && (
            <div className="bg-yellow-500 text-white text-xs font-bold px-2 py-1 rounded">
              Kargo Bedava
            </div>
          )}
        </div>

        {/* Out of Stock Badge */}
        {product.stock === 0 && (
          <div className="absolute top-2 right-2 bg-red-600 text-white text-xs font-bold px-2 py-1 rounded">
            Tükendi
          </div>
        )}
      </div>

      {/* Product Name */}
      <h3
        className="mt-4 font-medium text-white text-left truncate"
        style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
      >
        {product.productName}
      </h3>

      {/* Product Price and Discount */}
      <div className="mt-2 text-left">
        {product.discount > 0 ? (
          <div className="flex items-center">
            {/* Original Price in Red with Line-Through */}
            <span 
              className="text-red-600 line-through" 
              style={{ textDecorationColor: 'red' }}
            >
              {product.price.toFixed(2)} ₺
            </span>
            {/* Discounted Price */}
            <span className="ml-2 text-green-600 font-semibold">
              {(product.price * (100 - product.discount) / 100).toFixed(2)} ₺
            </span>
          </div>
        ) : (
          <span className="text-white font-semibold">
            {product.price.toFixed(2)} ₺
          </span>
        )}
      </div>
    </Link>
  );
}

export default ProductCard;