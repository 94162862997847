import React from 'react';

const SuccessPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-green-50">
      <div className="bg-white p-8 rounded shadow-md text-center">
        <h1 className="text-3xl font-bold text-green-600 mb-4">Ödeme Başarılı!</h1>
        <p className="text-lg text-gray-600 mb-6">Ödemeniz başarıyla alındı. Teşekkür ederiz!</p>
        <a
          href="/"
          className="bg-green-600 text-white px-6 py-3 rounded hover:bg-green-700 transition"
        >
          Ana Sayfaya Dön
        </a>
      </div>
    </div>
  );
};

export default SuccessPage;
