import React from 'react';
import { FaInstagram } from 'react-icons/fa'; // İkonlar için react-icons kullanımı
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white py-8 px-36">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row md:justify-between md:items-start gap-8">
          {/* Şirket Logosu ve Sosyal Medya İkonları */}
          <div className="flex flex-col items-start">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/elmalicollectibles-87dc4.appspot.com/o/eclogo.svg?alt=media&token=90d0a55e-00e8-4ef0-a575-d0902091b4c0"
              className="h-16 w-auto mb-4"
              alt="Elmalı Collectibles Logo"
            />
            <div className="flex space-x-4">
              <a
                href="https://www.instagram.com/elmalicollectibles"
                className="text-gray-500 ü
                -,
                hover:text-gray-800 transition-colors"
              >
       <div className="flex items-center justify-center md:justify-start">
            <img
              src="/followus.jpeg"
              alt="Instagram"
              className="h-12 w-auto object-contain"
            />
          </div>
              </a>
            </div>
          </div>

          {/* Ödeme Yöntemleri */}
          <div className="flex items-center justify-center md:justify-start">
            <img
              src="/iyzico.png"
              alt="Ödeme Yöntemleri"
              className="h-8 w-auto object-contain"
            />
          </div>

          {/* Legal Menüsü ve Sözleşmeler */}
          <div className="flex flex-col">
            <h2 className="text-base font-semibold text-gray-900 mb-4">
              Kurumsal
            </h2>
            <ul className="text-gray-600 font-normal space-y-2">
            <li>
                <Link to="/about-us" className="hover:underline">
                  Hakkımızda 
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="hover:underline">
                  Gizlilik Sözleşmesi
                </Link>
              </li>
              <li>
                <Link to="/terms" className="hover:underline">
                  Mesafeli Satış Sözleşmesi
                </Link>
              </li>
              <li>
                <Link to="/return-policy" className="hover:underline">
                  İade ve Cayma Hakkı
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 text-center sm:text-left mb-4 sm:mb-0">
            © 2024 Elmalı Collectibles™. All Rights Reserved.
          </span>
          <span className="text-sm text-gray-500 text-center sm:text-right">
            Designed & Developed With Our ♥
            <a
              href="https://elmalitech.com"
              className="hover:underline text-blue-800 font-medium"
              target="_blank"
              rel="noopener noreferrer"
            >
              Elmali Tech.
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
