import React, { useState, useEffect, useRef } from 'react';
import { db } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';
import ProductCard from './components/ProductCard';
import { Dialog, Disclosure } from '@headlessui/react';
import {
  XMarkIcon,
  FunnelIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from '@heroicons/react/24/outline';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import DarkNavbar from './DarkNavbar';
import { useLocation } from 'react-router-dom';

function ProductListPage() {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [universes, setUniverses] = useState([]);
  const [characters, setCharacters] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [scales, setScales] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedManufacturers, setSelectedManufacturers] = useState([]);
  const [selectedUniverses, setSelectedUniverses] = useState([]);
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [selectedScales, setSelectedScales] = useState([]);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 10000]);
  const [maxPrice, setMaxPrice] = useState(10000);
  const [showInStock, setShowInStock] = useState(true);
  const [showPreOrder, setShowPreOrder] = useState(true);
  const [showOutOfStock, setShowOutOfStock] = useState(true);
  const [searchTerm, setSearchTerm] = useState(''); // Arama terimi için state
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const location = useLocation();
  const panelRef = useRef(null);

  // Verileri çekme ve state'lere set etme işlemi
  useEffect(() => {
    const fetchData = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productSnapshot = await getDocs(productsCollection);
        const productList = productSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(productList);
        setFilteredProducts(productList);

        const prices = productList.map((product) => product.price);
        const minPriceFromProducts = Math.min(...prices);
        const maxPriceFromProducts = Math.max(...prices);

        setMaxPrice(maxPriceFromProducts);
        setPriceRange([minPriceFromProducts, maxPriceFromProducts]);

        const uniqueCategories = [
          ...new Set(
            productList.map((product) => product.category).filter(Boolean)
          ),
        ];
        const uniqueManufacturers = [
          ...new Set(
            productList.map((product) => product.manufacturer).filter(Boolean)
          ),
        ];
        setCategories(uniqueCategories);
        setManufacturers(uniqueManufacturers);

        const universesCollection = collection(db, 'universes');
        const universesSnapshot = await getDocs(universesCollection);
        const universesList = universesSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((universe) => universe.name);
        setUniverses(universesList.map((universe) => universe.name));

        const charactersCollection = collection(db, 'characters');
        const charactersSnapshot = await getDocs(charactersCollection);
        const charactersList = charactersSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((character) => character.name);
        setCharacters(charactersList.map((character) => character.name));

        const materialsCollection = collection(db, 'materials');
        const materialsSnapshot = await getDocs(materialsCollection);
        const materialsList = materialsSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((material) => material.name);
        setMaterials(materialsList.map((material) => material.name));

        const scalesCollection = collection(db, 'scales');
        const scalesSnapshot = await getDocs(scalesCollection);
        const scalesList = scalesSnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((scale) => scale.name);
        setScales(scalesList.map((scale) => scale.name));

        const uniqueSizes = [
          ...new Set(
            productList.map((product) => product.sizeRange).filter(Boolean)
          ),
        ];
        setSizes(uniqueSizes);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Eğer location state ile bir searchTerm geldiyse, arama işlemini başlat
  useEffect(() => {
    if (location.state?.searchTerm) {
      const search = location.state.searchTerm.toLowerCase().trim();
      setSearchTerm(search);
      applyFilters(search);
    }
  }, [location.state, products]);

  // Filtreleri uygulamak için kullanılan fonksiyon
  const applyFilters = (search = searchTerm) => {
    let tempProducts = products;

    // Arama terimi filtresi
    if (search) {
      tempProducts = tempProducts.filter((product) =>
        product.productName.toLowerCase().includes(search)
      );
    }

    // Kategori filtresi
    if (selectedCategories.length > 0) {
      tempProducts = tempProducts.filter((product) =>
        selectedCategories.includes(product.category)
      );
    }

    // Marka filtresi
    if (selectedManufacturers.length > 0) {
      tempProducts = tempProducts.filter((product) =>
        selectedManufacturers.includes(product.manufacturer)
      );
    }

    // Evren filtresi
    // Universe filter with a check for undefined values
    if (selectedUniverses.length > 0) {
      tempProducts = tempProducts.filter((product) =>
        selectedUniverses.some((universe) =>
          product.universe?.includes(universe) // Ensure product.universe is defined
        )
      );
    }

    // Karakter filtresi
    if (selectedCharacters.length > 0) {
      tempProducts = tempProducts.filter((product) =>
        selectedCharacters.some((character) =>
          product.character.includes(character)
        )
      );
    }

    // Malzeme filtresi
    if (selectedMaterials.length > 0) {
      tempProducts = tempProducts.filter((product) =>
        selectedMaterials.some((material) =>
          product.material.includes(material)
        )
      );
    }

    // Ölçek filtresi
    if (selectedScales.length > 0) {
      tempProducts = tempProducts.filter((product) =>
        selectedScales.includes(product.scale)
      );
    }

    // Boyut aralığı filtresi
    if (selectedSizes.length > 0) {
      tempProducts = tempProducts.filter((product) =>
        selectedSizes.includes(product.sizeRange)
      );
    }

    // Fiyat aralığı filtresi
    tempProducts = tempProducts.filter(
      (product) =>
        product.price >= priceRange[0] && product.price <= priceRange[1]
    );

    // Stok durumu filtresi
    tempProducts = tempProducts.filter((product) => {
      const inStock = product.stock > 0;
      const isPreOrder = product.preOrder;
      const outOfStock = product.stock === 0;

      return (
        (showInStock && inStock) ||
        (showPreOrder && isPreOrder) ||
        (showOutOfStock && outOfStock)
      );
    });

    // Tükenenler en altta olacak şekilde sıralama
    tempProducts = tempProducts.sort((a, b) => {
      if (a.stock === 0 && b.stock > 0) {
        return 1;
      } else if (a.stock > 0 && b.stock === 0) {
        return -1;
      }
      return 0;
    });

    setFilteredProducts(tempProducts);
  };

  useEffect(() => {
    applyFilters();
  }, [
    selectedCategories,
    selectedManufacturers,
    selectedUniverses,
    selectedCharacters,
    selectedMaterials,
    selectedScales,
    selectedSizes,
    priceRange,
    showInStock,
    showPreOrder,
    showOutOfStock,
    products,
  ]);

  const handleMultipleSelect = (value, setter, selectedValues) => {
    if (selectedValues.includes(value)) {
      setter(selectedValues.filter((val) => val !== value));
    } else {
      setter([...selectedValues, value]);
    }
  };

  const resetFilters = () => {
    setSelectedCategories([]);
    setSelectedManufacturers([]);
    setSelectedUniverses([]);
    setSelectedCharacters([]);
    setSelectedMaterials([]);
    setSelectedScales([]);
    setSelectedSizes([]);
    setPriceRange([0, maxPrice]);
    setShowInStock(true);
    setShowPreOrder(true);
    setShowOutOfStock(true);
    setFilteredProducts(products);
    setSearchTerm(''); // Arama terimini sıfırla
  };

  return (
    <>
      <DarkNavbar />
      <div className=" text-gray-300">
        <div className="mx-auto max-w-7xl px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
          <h1 className="text-3xl font-bold tracking-tight text-white">
            Ürün Listesi
          </h1>

          {searchTerm && (
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold">
                "{searchTerm}" için arama sonuçları
              </h2>
              <button
                onClick={resetFilters}
                className="text-red-500 underline"
              >
                Sıfırla
              </button>
            </div>
          )}

          <div className="pt-12 lg:grid lg:grid-cols-4 lg:gap-x-8">
            <aside className="hidden lg:block">
              {/* Filtre menüsü */}
              <form className="space-y-6 divide-y divide-gray-600">
                {[
                  {
                    title: 'Kategori',
                    items: categories,
                    state: selectedCategories,
                    setState: setSelectedCategories,
                  },
                  {
                    title: 'Marka',
                    items: manufacturers,
                    state: selectedManufacturers,
                    setState: setSelectedManufacturers,
                  },
                  {
                    title: 'Evren',
                    items: universes,
                    state: selectedUniverses,
                    setState: setSelectedUniverses,
                  },
                  {
                    title: 'Karakter',
                    items: characters,
                    state: selectedCharacters,
                    setState: setSelectedCharacters,
                  },
                  {
                    title: 'Boyut Aralığı',
                    items: sizes,
                    state: selectedSizes,
                    setState: setSelectedSizes,
                  },
                  {
                    title: 'Ölçek',
                    items: scales,
                    state: selectedScales,
                    setState: setSelectedScales,
                  },
                  {
                    title: 'Malzeme',
                    items: materials,
                    state: selectedMaterials,
                    setState: setSelectedMaterials,
                  },
                ].map((filter, index) => (
                  <Disclosure key={index}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`flex justify-between w-full py-2 text-sm font-medium text-left bg-gray-800 rounded-lg hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 transition-colors ${
                            open ? 'ring-2 ring-offset-2 ring-indigo-500' : ''
                          }`}
                        >
                          <span className="text-white">{filter.title}</span>
                          {open ? (
                            <ChevronUpIcon className="w-5 h-5 text-white" />
                          ) : (
                            <ChevronDownIcon className="w-5 h-5 text-white" />
                          )}
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4 pb-2">
                          <div className="mt-3 max-h-32 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400">
                            {filter.items.map((item, idx) => (
                              <div key={idx} className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={filter.state.includes(item)}
                                  onChange={() =>
                                    handleMultipleSelect(
                                      item,
                                      filter.setState,
                                      filter.state
                                    )
                                  }
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label className="ml-2 text-sm text-gray-300">
                                  {item}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}

                {/* Fiyat Aralığı */}
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex justify-between w-full py-2 text-sm font-medium text-left bg-gray-800 rounded-lg hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 transition-colors">
                        <span className="text-white">Fiyat Aralığı</span>
                        {open ? (
                          <ChevronUpIcon className="w-5 h-5 text-white" />
                        ) : (
                          <ChevronDownIcon className="w-5 h-5 text-white" />
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel className="pt-4 pb-2">
                        <div className="mt-3">
                          <Slider
                            range
                            min={0}
                            max={maxPrice}
                            value={priceRange}
                            onChange={setPriceRange}
                            trackStyle={{ backgroundColor: '#3b82f6' }}
                            handleStyle={{
                              borderColor: '#3b82f6',
                              backgroundColor: '#3b82f6',
                            }}
                          />
                          <div className="mt-2 flex justify-between text-sm text-gray-300">
                            <span>{priceRange[0]} ₺</span>
                            <span>{priceRange[1]} ₺</span>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

                {/* Stok durumu */}
                <div className="flex flex-col gap-3 mt-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={showInStock}
                      onChange={(e) => setShowInStock(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-sm text-gray-300">
                      Stoktakiler
                    </span>
                  </label>

                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={showPreOrder}
                      onChange={(e) => setShowPreOrder(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-sm text-gray-300">
                      Ön siparişler
                    </span>
                  </label>

                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={showOutOfStock}
                      onChange={(e) => setShowOutOfStock(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-sm text-gray-300">
                      Tükenenler
                    </span>
                  </label>
                </div>

                {/* Filtreleri sıfırla butonu */}
                <div className="pt-6">
                  <button
                    type="button"
                    onClick={resetFilters}
                    className="w-full px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700 transition-colors"
                  >
                    Filtreleri Sıfırla
                  </button>
                </div>
              </form>
            </aside>

            {/* Ürünler Listesi */}
            <div className="mt-6 lg:col-span-3">
              <div className="grid grid-cols-2 p-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6">
                {filteredProducts.length > 0 ? (
                  filteredProducts.map((product) => (
                    <ProductCard key={product.id} product={product} />
                  ))
                ) : (
                  <p className="text-center col-span-full text-gray-500">
                    Ürün bulunamadı.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Mobil filtre menüsü */}
        <div className="fixed bottom-5 right-5 lg:hidden">
          <button
            type="button"
            className="flex items-center p-3 bg-blue-600 rounded-full text-white shadow-lg hover:bg-blue-700"
            onClick={() => setMobileFiltersOpen(true)}
          >
            <FunnelIcon className="h-6 w-6 mr-2" />
            Filtreler
          </button>
        </div>

        <Dialog
          open={mobileFiltersOpen}
          onClose={() => setMobileFiltersOpen(false)}
          className="relative z-40 lg:hidden"
        >
          <div className="fixed inset-0 bg-black bg-opacity-50" />
          <div className="fixed inset-0 z-40 flex items-end">
            <Dialog.Panel
              ref={panelRef}
              tabIndex={-1}
              className="w-full max-w-md bg-white rounded-t-lg shadow-xl p-6 overflow-y-auto max-h-[80vh]"
            >
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-semibold text-gray-800">
                  Filtreler
                </h2>
                <button
                  type="button"
                  className="p-1 text-gray-400 hover:text-gray-600"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>

              <form className="space-y-4">
                {/* Mobil filtre içerikleri */}
                {[
                  {
                    title: 'Kategori',
                    items: categories,
                    state: selectedCategories,
                    setState: setSelectedCategories,
                  },
                  {
                    title: 'Marka',
                    items: manufacturers,
                    state: selectedManufacturers,
                    setState: setSelectedManufacturers,
                  },
                  {
                    title: 'Evren',
                    items: universes,
                    state: selectedUniverses,
                    setState: setSelectedUniverses,
                  },
                  {
                    title: 'Karakter',
                    items: characters,
                    state: selectedCharacters,
                    setState: setSelectedCharacters,
                  },
                  {
                    title: 'Boyut Aralığı',
                    items: sizes,
                    state: selectedSizes,
                    setState: setSelectedSizes,
                  },
                  {
                    title: 'Ölçek',
                    items: scales,
                    state: selectedScales,
                    setState: setSelectedScales,
                  },
                  {
                    title: 'Malzeme',
                    items: materials,
                    state: selectedMaterials,
                    setState: setSelectedMaterials,
                  },
                ].map((filter, index) => (
                  <Disclosure key={index}>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="flex justify-between w-full py-2 text-sm font-medium text-left text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
                          <span>{filter.title}</span>
                          {open ? (
                            <ChevronUpIcon className="w-5 h-5 text-gray-700" />
                          ) : (
                            <ChevronDownIcon className="w-5 h-5 text-gray-700" />
                          )}
                        </Disclosure.Button>
                        <Disclosure.Panel className="pt-4 pb-2">
                          <div className="mt-3 space-y-2 max-h-32 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
                            {filter.items.map((item, idx) => (
                              <div key={idx} className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={filter.state.includes(item)}
                                  onChange={() =>
                                    handleMultipleSelect(
                                      item,
                                      filter.setState,
                                      filter.state
                                    )
                                  }
                                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                />
                                <label className="ml-2 text-sm text-gray-700">
                                  {item}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}

                {/* Fiyat Aralığı */}
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex justify-between w-full py-2 text-sm font-medium text-left text-gray-700 bg-gray-200 rounded-lg hover:bg-gray-300 focus:outline-none focus-visible:ring focus-visible:ring-indigo-500 focus-visible:ring-opacity-75">
                        <span>Fiyat Aralığı</span>
                        {open ? (
                          <ChevronUpIcon className="w-5 h-5 text-gray-700" />
                        ) : (
                          <ChevronDownIcon className="w-5 h-5 text-gray-700" />
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel className="pt-4 pb-2">
                        <div className="mt-3">
                          <Slider
                            range
                            min={0}
                            max={maxPrice}
                            value={priceRange}
                            onChange={setPriceRange}
                            trackStyle={{ backgroundColor: '#3b82f6' }}
                            handleStyle={{
                              borderColor: '#3b82f6',
                              backgroundColor: '#3b82f6',
                            }}
                          />
                          <div className="mt-2 flex justify-between text-sm">
                            <span>{priceRange[0]} ₺</span>
                            <span>{priceRange[1]} ₺</span>
                          </div>
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>

                {/* Stok durumu */}
                <div className="flex flex-col gap-3 mt-4">
                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={showInStock}
                      onChange={(e) => setShowInStock(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      Stoktakiler
                    </span>
                  </label>

                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={showPreOrder}
                      onChange={(e) => setShowPreOrder(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      Ön siparişler
                    </span>
                  </label>

                  <label className="inline-flex items-center">
                    <input
                      type="checkbox"
                      checked={showOutOfStock}
                      onChange={(e) => setShowOutOfStock(e.target.checked)}
                      className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out"
                    />
                    <span className="ml-2 text-sm text-gray-700">
                      Tükenenler
                    </span>
                  </label>
                </div>

                {/* Filtreleri sıfırla butonu */}
                <div className="pt-6">
                  <button
                    type="button"
                    onClick={resetFilters}
                    className="w-full px-4 py-2 text-white bg-red-600 rounded-md hover:bg-red-700 transition-colors"
                  >
                    Filtreleri Sıfırla
                  </button>
                </div>
              </form>
            </Dialog.Panel>
          </div>
        </Dialog>
      </div>
    </>
  );
}

export default ProductListPage;
