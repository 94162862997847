import React from 'react';
import Navbar from './Navbar';

const ReturnPolicy = () => {
  return (
<>
<Navbar/>
    <div className="bg-gray-100 min-h-screen py-10 px-4 sm:px-6 lg:px-8">
    <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">
        ELMALI COLLECTIBLES İADE VE CAYMA HAKKI SÖZLEŞMESİ
      </h1>

      <div className="space-y-8 text-gray-700">
        <section>
          <h2 className="text-xl font-semibold">1. İade ve Cayma Hakkının Kapsamı</h2>
          <p className="mt-2">
            Elmalı Collectibles üzerinden yapılan alışverişlerde, 6502 sayılı Tüketicinin Korunması
            Hakkında Kanun ve Mesafeli Sözleşmelere Dair Yönetmelik uyarınca, Alıcı, teslim aldığı
            ürünü 14 gün içerisinde hiçbir gerekçe göstermeksizin iade etme ve cayma hakkını
            kullanma hakkına sahiptir.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold">2. Cayma Hakkının Kullanımı</h2>
          <p className="mt-2">
            Alıcı, cayma hakkını kullanmak için ürünü teslim aldığı tarihten itibaren 14 gün içinde
            cayma bildirimini, aşağıdaki iletişim yolları ile Satıcı'ya iletmelidir:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>
              <strong>E-posta:</strong> elmalicollectiblesiletisim@gmail.com
            </li>
            <li>
              <strong>Adres:</strong> Onur Mah. Limon Sok. No: 1/73 Duru Plaza İş Merkezi Balçova
              / İZMİR
            </li>
            <li>
              <strong>Sosyal Medya:</strong>{' '}
              <a
                href="https://www.instagram.com/elmalicollectibles/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                https://www.instagram.com/elmalicollectibles/
              </a>
            </li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold">3. İade Şartları</h2>
          <p className="mt-2">
            Cayma hakkının kullanılabilmesi için iade edilecek ürünlerin:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>Orijinal kutusunda, ambalajı bozulmamış, eksiksiz ve hasarsız olması,</li>
            <li>Ürünle birlikte gönderilen tüm aksesuarlar ve fatura ile iade edilmesi gerekmektedir.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold">4. İade İşlemleri</h2>
          <p className="mt-2">
            Alıcı, cayma hakkını kullanarak ürünü Satıcı'ya iade etmekle yükümlüdür. Ürünün iadesi
            için Alıcı, ürünü Satıcı’nın belirttiği kargo şirketine teslim etmelidir. İade kargo
            masrafları, cayma hakkının kullanılması halinde Alıcı'ya aittir.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold">5. Cayma Hakkının İstisnaları</h2>
          <p className="mt-2">
            Aşağıdaki ürünler cayma hakkına tabi değildir:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>Alıcı'nın istekleri doğrultusunda kişiselleştirilen ürünler,</li>
            <li>Ambalajı açılmış, tekrar satışı mümkün olmayan hijyenik ürünler,</li>
            <li>Dijital içerikler ve hizmetler.</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold">6. Ücret İadesi</h2>
          <p className="mt-2">
            Alıcı'nın cayma hakkını kullanması durumunda, ürünün iadesini takiben en geç 14 gün
            içinde, ödediği tutar Alıcı'nın ödeme yaptığı yöntemle iade edilecektir. İade sırasında
            yapılan kargo ücreti, Alıcı'ya iade edilmez.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold">7. Hasarlı ve Ayıplı Ürün İadesi</h2>
          <p className="mt-2">
            Alıcı, teslim aldığı ürünün hasarlı veya ayıplı olması durumunda, ürünü teslim
            tarihinden itibaren 14 gün içinde cayma hakkını kullanabilir. Hasarlı ürünlerde iade
            kargo masrafı Satıcı'ya aittir.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold">8. İade Edilecek Adres</h2>
          <p className="mt-2">
            İade edilmek istenen ürünlerin gönderileceği adres: Elmalı Collectibles, Onur Mah.
            Limon Sok. No: 1/73 Duru Plaza İş Merkezi Balçova / İZMİR
          </p>
        </section>
      </div>
    </div>
  </div>
</>
  );
};

export default ReturnPolicy;
