import React, { useState, useEffect } from 'react';
import { db, storage } from './firebaseConfig';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Swal from 'sweetalert2';

function CreateProduct() {
  const [productName, setProductName] = useState('');
  const [limit, setLimit] = useState('');
  const [boyut, setBoyut] = useState('');
  const [price, setPrice] = useState('');
  const [discount, setDiscount] = useState('');
  const [discountedPrice, setDiscountedPrice] = useState('');
  const [details, setDetails] = useState(''); // Ürün açıklaması
  const [material, setMaterial] = useState([]); // Çoklu seçim için array
  const [materials, setMaterials] = useState([]);
  const [manufacturer, setManufacturer] = useState('');
  const [manufacturers, setManufacturers] = useState([]);
  const [category, setCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState(''); // Etiketler
  const [coverImage, setCoverImage] = useState(null);
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [universe, setUniverse] = useState([]); // Çoklu seçim için array
  const [universes, setUniverses] = useState([]);
  const [featured, setFeatured] = useState(false); // Öne Çıkan
  const [newArrival, setNewArrival] = useState(false);
  const [preOrder, setPreOrder] = useState(false); // Ön Sipariş
  const [preOrderDateRange, setPreOrderDateRange] = useState({ start: '', end: '' });
  const [newCategory, setNewCategory] = useState('');
  const [newManufacturer, setNewManufacturer] = useState('');
  const [newMaterial, setNewMaterial] = useState('');
  const [newUniverse, setNewUniverse] = useState('');
  const [newCharacter, setNewCharacter] = useState('');
  const [characters, setCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState([]); // Çoklu seçim için array
  const [isSold, setIsSold] = useState(false); // Satıldı mı?
  const [stock, setStock] = useState(''); // Stok sayısı
  const [isSecondHand, setIsSecondHand] = useState(false); // İkinci el mi?
  const [sizeRange, setSizeRange] = useState('');
  const [scale, setScale] = useState('');

  useEffect(() => {
    if (price && discount) {
      const calculatedPrice = price * (100 - discount) / 100;
      setDiscountedPrice(calculatedPrice.toFixed(2));
    } else {
      setDiscountedPrice('');
    }
  }, [price, discount]);

  // Eğer "Satıldı" işaretlenirse stok 0 yapılır
  useEffect(() => {
    if (isSold) {
      setStock(0);
    } else {
      setStock('');
    }
  }, [isSold]);

  useEffect(() => {
    const fetchCategories = async () => {
      const categoriesCollection = collection(db, 'categories');
      const categorySnapshot = await getDocs(categoriesCollection);
      const categoryList = categorySnapshot.docs.map((doc) => doc.data().name);
      setCategories(categoryList);
    };

    const fetchUniverses = async () => {
      const universesCollection = collection(db, 'universes');
      const universeSnapshot = await getDocs(universesCollection);
      const universeList = universeSnapshot.docs.map((doc) => doc.data().name);
      setUniverses(universeList);
    };

    const fetchManufacturers = async () => {
      const manufacturersCollection = collection(db, 'manufacturers');
      const manufacturerSnapshot = await getDocs(manufacturersCollection);
      const manufacturerList = manufacturerSnapshot.docs.map((doc) => doc.data().name);
      setManufacturers(manufacturerList);
    };

    const fetchMaterials = async () => {
      const materialsCollection = collection(db, 'materials');
      const materialsSnapshot = await getDocs(materialsCollection);
      const materialList = materialsSnapshot.docs.map((doc) => doc.data().name);
      setMaterials(materialList);
    };

    const fetchCharacters = async () => {
      const charactersCollection = collection(db, 'characters');
      const characterSnapshot = await getDocs(charactersCollection);
      const characterList = characterSnapshot.docs.map((doc) => doc.data().name);
      setCharacters(characterList);
    };

    fetchCategories();
    fetchUniverses();
    fetchManufacturers();
    fetchMaterials();
    fetchCharacters();
  }, []);

  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  const handleImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setImages(files);
    const previews = files.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const handleImageRemove = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);

    const newPreviews = [...imagePreviews];
    newPreviews.splice(index, 1);
    setImagePreviews(newPreviews);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let coverImageUrl = '';
      if (coverImage) {
        const coverImageRef = ref(storage, `products/${coverImage.name}`);
        await uploadBytes(coverImageRef, coverImage);
        coverImageUrl = await getDownloadURL(coverImageRef);
      }

      const imageUrls = [];
      for (let image of images) {
        const imageRef = ref(storage, `products/${image.name}`);
        await uploadBytes(imageRef, image);
        const imageUrl = await getDownloadURL(imageRef);
        imageUrls.push(imageUrl);
      }

      await addDoc(collection(db, 'products'), {
        productName,
        limit,
        boyut,
        price: parseFloat(price),
        discount: parseFloat(discount),
        details, // Açıklama
        material, // Güncellendi: Malzemeler array olarak kaydedilecek
        manufacturer,
        category,
        tags: tags.split(',').map((tag) => tag.trim()), // Etiketler
        universe, // Güncellendi: Evrenler array olarak kaydedilecek
        featured, // Öne Çıkan
        newArrival,
        preOrder, // Ön Sipariş
        preOrderDateRange: preOrder ? preOrderDateRange : null,
        coverImageUrl,
        imageUrls,
        isSold, // Satıldı mı?
        stock: parseInt(stock), // Stok sayısı
        isSecondHand, // Güncellendi: İkinci el mi?
        character: selectedCharacter, // Güncellendi: Karakterler array olarak kaydedilecek
        sizeRange,
        scale,
      });

      Swal.fire('Başarılı!', 'Ürün başarıyla oluşturuldu!', 'success');

      // Formu sıfırlama
      setProductName('');
      setLimit('');
      setBoyut('');
      setPrice('');
      setDiscount('');
      setDiscountedPrice('');
      setDetails(''); // Açıklama sıfırlanır
      setMaterial([]);
      setManufacturer('');
      setCategory('');
      setTags(''); // Etiketler sıfırlanır
      setUniverse([]);
      setFeatured(false); // Öne Çıkan sıfırlanır
      setNewArrival(false);
      setPreOrder(false); // Ön Sipariş sıfırlanır
      setPreOrderDateRange({ start: '', end: '' });
      setCoverImage(null);
      setImages([]);
      setImagePreviews([]);
      setIsSold(false); // Satıldı mı sıfırlanır
      setStock(''); // Stok sıfırlanır
      setIsSecondHand(false); // İkinci el sıfırlanır
      setSelectedCharacter([]);
      setSizeRange('');
      setScale('');
    } catch (error) {
      console.error('Ürün oluşturulurken hata oluştu:', error);
      Swal.fire('Hata!', 'Ürün oluşturulamadı. Lütfen tekrar deneyin.', 'error');
    }
  };

  const handleCategorySubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'categories'), { name: newCategory });
      setCategories([...categories, newCategory]);
      setNewCategory('');
      Swal.fire('Başarılı!', 'Kategori başarıyla eklendi!', 'success');
    } catch (error) {
      console.error('Kategori eklenirken hata oluştu:', error);
      Swal.fire('Hata!', 'Kategori eklenemedi. Lütfen tekrar deneyin.', 'error');
    }
  };

  const handleManufacturerSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'manufacturers'), { name: newManufacturer });
      setManufacturers([...manufacturers, newManufacturer]);
      setNewManufacturer('');
      Swal.fire('Başarılı!', 'Üretici firma başarıyla eklendi!', 'success');
    } catch (error) {
      console.error('Üretici firma eklenirken hata oluştu:', error);
      Swal.fire('Hata!', 'Üretici firma eklenemedi. Lütfen tekrar deneyin.', 'error');
    }
  };

  const handleMaterialSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'materials'), { name: newMaterial });
      setMaterials([...materials, newMaterial]);
      setNewMaterial('');
      Swal.fire('Başarılı!', 'Malzeme başarıyla eklendi!', 'success');
    } catch (error) {
      console.error('Malzeme eklenirken hata oluştu:', error);
      Swal.fire('Hata!', 'Malzeme eklenemedi. Lütfen tekrar deneyin.', 'error');
    }
  };

  const handleUniverseSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'universes'), { name: newUniverse });
      setUniverses([...universes, newUniverse]);
      setNewUniverse('');
      Swal.fire('Başarılı!', 'Evren başarıyla eklendi!', 'success');
    } catch (error) {
      console.error('Evren eklenirken hata oluştu:', error);
      Swal.fire('Hata!', 'Evren eklenemedi. Lütfen tekrar deneyin.', 'error');
    }
  };

  const handleCharacterSubmit = async (e) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, 'characters'), { name: newCharacter });
      setCharacters([...characters, newCharacter]);
      setNewCharacter('');
      Swal.fire('Başarılı!', 'Karakter başarıyla eklendi!', 'success');
    } catch (error) {
      console.error('Karakter eklenirken hata oluştu:', error);
      Swal.fire('Hata!', 'Karakter eklenemedi. Lütfen tekrar deneyin.', 'error');
    }
  };

  return (
    <div className="container mx-auto p-4 bg-[#191919] text-white">
      <h1 className="text-2xl font-bold mb-4">Ürün Oluştur</h1>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label className="block text-sm font-medium">Ürün Adı</label>
          <input
            type="text"
            value={productName}
            onChange={(e) => setProductName(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Dünya Limiti</label>
          <input
            type="text"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Boyut</label>
          <input
            type="text"
            value={boyut}
            onChange={(e) => setBoyut(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Fiyat</label>
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium">İndirim (%)</label>
          <input
            type="number"
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          />
          {discountedPrice && (
            <p className="text-sm text-green-400 mt-1">
              İndirimli Fiyat: {discountedPrice} ₺
            </p>
          )}
        </div>

        <div>
          <label className="block text-sm font-medium">Açıklama</label>
          <textarea
            value={details}
            onChange={(e) => setDetails(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          />
        </div>

        <div>
          <label className="block text-sm font-medium">Etiketler (virgülle ayrılmış)</label>
          <input
            type="text"
            value={tags}
            onChange={(e) => setTags(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          />
        </div>

        {/* Üretici Firma */}
        <div>
          <label className="block text-sm font-medium">Üretici Firma</label>
          <select
            value={manufacturer}
            onChange={(e) => setManufacturer(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
            required
          >
            <option value="">Üretici Firma Seçin</option>
            {manufacturers.map((man, index) => (
              <option key={index} value={man}>
                {man}
              </option>
            ))}
          </select>
        </div>



        {/* Malzeme (Çoklu seçim) */}
        <div>
          <label className="block text-sm font-medium">Malzeme</label>
          <select
            multiple
            value={material}
            onChange={(e) => setMaterial([...e.target.selectedOptions].map(o => o.value))}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          >
            {materials.map((mat, index) => (
              <option key={index} value={mat}>
                {mat}
              </option>
            ))}
          </select>
        </div>



        {/* Kategori */}
        <div>
          <label className="block text-sm font-medium">Kategori</label>
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
            required
          >
            <option value="">Kategori Seçin</option>
            {categories.map((cat, index) => (
              <option key={index} value={cat}>
                {cat}
              </option>
            ))}
          </select>
        </div>



        {/* Evren (Çoklu seçim) */}
        <div>
          <label className="block text-sm font-medium">Evren</label>
          <select
            multiple
            value={universe}
            onChange={(e) => setUniverse([...e.target.selectedOptions].map(o => o.value))}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          >
            {universes.map((uni, index) => (
              <option key={index} value={uni}>
                {uni}
              </option>
            ))}
          </select>
        </div>



        {/* Karakter (Çoklu seçim) */}
        <div>
          <label className="block text-sm font-medium">Karakter</label>
          <select
            multiple
            value={selectedCharacter}
            onChange={(e) => setSelectedCharacter([...e.target.selectedOptions].map(o => o.value))}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          >
            {characters.map((char, index) => (
              <option key={index} value={char}>
                {char}
              </option>
            ))}
          </select>
        </div>



        {/* Boyut Aralığı */}
        <div>
          <label className="block text-sm font-medium">Boyut Aralığı</label>
          <select
            value={sizeRange}
            onChange={(e) => setSizeRange(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          >
            <option value="">Boyut Aralığı Seçin</option>
            <option value="1-5 inch">1-5 inch</option>
            <option value="5-10 inch">6-10 inch</option>
            <option value="10-15 inch">11-15 inch</option>
            <option value="15-25 inch">16-25 inch</option>
            <option value="25-50 inch">16-50 inch</option>
            <option value="50 inch ve üzeri">51 inch ve üzeri</option>
          </select>
        </div>

        {/* Ölçek */}
        <div>
          <label className="block text-sm font-medium">Ölçek</label>
          <select
            value={scale}
            onChange={(e) => setScale(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          >
            <option value="">Ölçek Seçin</option>
            <option value="1:1 life size">1:1 life size</option>
            <option value="1:2">1:2</option>
            <option value="1:3">1:3</option>
            <option value="1:4">1:4</option>
            <option value="1:5">1:5</option>
            <option value="1:6">1:6</option>
            <option value="1:7">1:7</option>
            <option value="1:8">1:8</option>
            <option value="1:9">1:9</option>
            <option value="1:10">1:10</option>
            <option value="1:15">1:15</option>
            <option value="1:18">1:18</option>
            <option value="1:32">1:32</option>
            <option value="1:43">1:43</option>
          </select>
        </div>

        {/* Kapak Resmi */}
        <div>
          <label className="block text-sm font-medium">Kapak Fotoğrafı</label>
          <input
            type="file"
            onChange={handleCoverImageChange}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
            required
          />
        </div>

        {/* Ürün Fotoğrafları */}
        <div>
          <label className="block text-sm font-medium">Ürün Fotoğrafları</label>
          <input
            type="file"
            multiple
            onChange={handleImagesChange}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
          />
          {imagePreviews.length > 0 && (
            <div className="mt-4">
              <h2 className="text-lg font-medium">Yüklenen Fotoğraflar</h2>
              <div className="flex space-x-4 mt-2">
                {imagePreviews.map((preview, index) => (
                  <div key={index} className="relative">
                    <img src={preview} alt={`preview-${index}`} className="w-24 h-24 object-cover rounded-md" />
                    <button
                      type="button"
                      onClick={() => handleImageRemove(index)}
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 text-xs"
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        {/* Stok Sayısı */}
        <div>
          <label className="block text-sm font-medium">Stok Sayısı</label>
          <input
            type="number"
            value={stock}
            onChange={(e) => setStock(e.target.value)}
            className="mt-1 block w-full p-2 border border-gray-600 rounded-md bg-[#2b2b2b] text-white"
            disabled={isSold}
          />
        </div>

        {/* Satıldı mı? */}
        <div>
          <label className="block text-sm font-medium">Satıldı mı?</label>
          <input
            type="checkbox"
            checked={isSold}
            onChange={(e) => setIsSold(e.target.checked)}
            className="mt-1 block"
          />
        </div>

        {/* İkinci El mi? */}
        <div>
          <label className="block text-sm font-medium">İkinci El mi?</label>
          <input
            type="checkbox"
            checked={isSecondHand}
            onChange={(e) => setIsSecondHand(e.target.checked)}
            className="mt-1 block"
          />
        </div>

        {/* Öne Çıkan */}
        <div>
          <label className="block text-sm font-medium">Öne Çıkan mı?</label>
          <input
            type="checkbox"
            checked={featured}
            onChange={(e) => setFeatured(e.target.checked)}
            className="mt-1 block"
          />
        </div>

        {/* Ön Sipariş */}
        <div>
          <label className="block text-sm font-medium">Ön Sipariş mi?</label>
          <input
            type="checkbox"
            checked={preOrder}
            onChange={(e) => setPreOrder(e.target.checked)}
            className="mt-1 block"
          />
        </div>

        <button
          type="submit"
          className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          Ürün Oluştur
        </button>
      </form>
    </div>
  );
}

export default CreateProduct;
