import { useState, useEffect, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { UserCircleIcon, MapPinIcon, BellIcon, HeartIcon, ArrowUturnRightIcon, CheckCircleIcon, PlusIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import Navbar from '../Navbar';
import Footer from '../components/Footer';
import { db, auth } from '../firebaseConfig';
import { collection, doc, addDoc, getDocs, deleteDoc, updateDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

const secondaryNavigation = [
  { name: 'Kişisel Bilgilerim', href: '/profile', icon: UserCircleIcon, current: false },
  { name: 'Adreslerim', href: '#', icon: MapPinIcon, current: true },
  { name: 'Favorilerim', href: '/favorites', icon: HeartIcon, current: false },
  { name: 'Gelince Haber Ver Listem', href: '#', icon: BellIcon, current: false },
  { name: 'Aktif Siparişlerim', href: '#', icon: ArrowUturnRightIcon, current: false },
  { name: 'Geçmiş Siparişlerim', href: '#', icon: CheckCircleIcon, current: false },
];

export default function Adres() {
  const [addresses, setAddresses] = useState([]);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentAddress, setCurrentAddress] = useState({
    addressName: '',
    name: '',
    surname: '',
    address: '',
    city: '',
    country: 'Türkiye',
    zipCode: '',
    phone: '',
  });
  const [editingAddress, setEditingAddress] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchAddresses(currentUser.uid);
      } else {
        Swal.fire({
          title: 'Giriş Yapın',
          text: 'Bu sayfaya erişmek için giriş yapmanız gerekmektedir.',
          icon: 'info',
          confirmButtonText: 'Tamam',
        }).then(() => {
          navigate('/login');
        });
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const fetchAddresses = async (userId) => {
    try {
      const addressRef = collection(db, `users/${userId}/addresses`);
      const addressSnapshot = await getDocs(addressRef);
      const addressList = addressSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAddresses(addressList);
    } catch (error) {
      console.error('Adresler alınırken hata oluştu:', error);
    }
  };

  const openModal = (address = null) => {
    if (address) {
      setCurrentAddress(address);
      setEditingAddress(address);
    } else {
      setCurrentAddress({
        addressName: '',
        name: '',
        surname: '',
        address: '',
        city: '',
        country: 'Türkiye',
        zipCode: '',
        phone: '',
      });
      setEditingAddress(null);
    }
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setCurrentAddress({
      addressName: '',
      name: '',
      surname: '',
      address: '',
      city: '',
      country: 'Türkiye',
      zipCode: '',
      phone: '',
    });
    setEditingAddress(null);
    setIsModalOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddOrUpdateAddress = async () => {
    if (user) {
      try {
        const addressRef = collection(db, `users/${user.uid}/addresses`);
        if (editingAddress) {
          const addressDoc = doc(db, `users/${user.uid}/addresses/${editingAddress.id}`);
          await updateDoc(addressDoc, currentAddress);
          Swal.fire('Başarılı', 'Adres güncellendi!', 'success');
        } else {
          await addDoc(addressRef, currentAddress);
          Swal.fire('Başarılı', 'Adres eklendi!', 'success');
        }
        fetchAddresses(user.uid);
        closeModal();
      } catch (error) {
        Swal.fire('Hata', 'Adres eklenirken veya güncellenirken bir hata oluştu!', 'error');
        console.error('Adres ekleme/güncelleme hatası:', error);
      }
    }
  };

  const handleDeleteAddress = async (addressId) => {
    const result = await Swal.fire({
      title: 'Emin misiniz?',
      text: 'Bu adresi silmek istediğinizden emin misiniz?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Evet, sil',
      cancelButtonText: 'İptal',
    });

    if (result.isConfirmed && user) {
      try {
        const addressDoc = doc(db, `users/${user.uid}/addresses/${addressId}`);
        await deleteDoc(addressDoc);
        Swal.fire('Başarılı', 'Adres silindi!', 'success');
        fetchAddresses(user.uid);
      } catch (error) {
        Swal.fire('Hata', 'Adres silinirken bir hata oluştu!', 'error');
        console.error('Adres silme hatası:', error);
      }
    }
  };

  return (
    <>
      <Navbar />

      <div className="bg-white h-screen">
        <main className="mx-auto max-w-7xl pb-10 lg:px-8 lg:py-12">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside className="px-2 py-6 sm:px-6 lg:col-span-3 lg:px-0 lg:py-0">
              <nav className="space-y-1">
                {secondaryNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? 'page' : undefined}
                    className={`${item.current ? 'bg-gray-50 text-orange-600' : 'text-gray-900'} group flex items-center rounded-md px-3 py-2 text-sm font-medium`}
                  >
                    <item.icon
                      aria-hidden="true"
                      className={`${item.current ? 'text-orange-500' : 'text-gray-400'} -ml-1 mr-3 h-6 w-6 flex-shrink-0`}
                    />
                    <span className="truncate">{item.name}</span>
                  </a>
                ))}
              </nav>
            </aside>

            <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
              <section aria-labelledby="address-details-heading">
                <div className="flex justify-between items-center">
                  <h2 id="address-details-heading" className="text-lg font-medium text-gray-900">Teslimat Adresleri</h2>
                  <button
                    type="button"
                    className="inline-flex items-center text-sm font-medium text-orange-600 hover:text-orange-500"
                    onClick={() => openModal()}
                  >
                    <PlusIcon className="h-5 w-5 mr-1" />
                    Ekle
                  </button>
                </div>

                <ul className="mt-4 space-y-2">
                  {addresses.length > 0 ? (
                    addresses.map((address) => (
                      <li key={address.id} className="p-4 bg-white rounded-md shadow flex justify-between items-center">
                        <div>
                          <div><strong>{address.addressName}</strong></div>
                          <div>{address.name} {address.surname}</div>
                          <div>{address.address}</div>
                          <div>{address.city}, {address.country} {address.zipCode}</div>
                          <div>Telefon: {address.phone}</div>
                        </div>
                        <div className="flex items-center space-x-3">
                          <button
                            type="button"
                            className="text-orange-600 hover:text-orange-500"
                            onClick={() => openModal(address)}
                          >
                            <PencilIcon className="h-5 w-5" />
                          </button>
                          <button
                            type="button"
                            className="text-red-600 hover:text-red-500"
                            onClick={() => handleDeleteAddress(address.id)}
                          >
                            <TrashIcon className="h-5 w-5" />
                          </button>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li className="p-4 bg-white rounded-md shadow">Teslimat adresiniz yok.</li>
                  )}
                </ul>
              </section>
            </div>
          </div>
        </main>
      </div>

      {/* Add/Edit Modal */}
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title as="h3" className="text-lg font-medium text-gray-900">
                    {editingAddress ? 'Adresi Düzenle' : 'Yeni Adres Ekle'}
                  </Dialog.Title>
                  <div className="mt-4 space-y-4">
                    <input
                      type="text"
                      name="addressName"
                      placeholder="Adres İsmi"
                      className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      value={currentAddress.addressName}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      name="name"
                      placeholder="İsim"
                      className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      value={currentAddress.name}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      name="surname"
                      placeholder="Soyisim"
                      className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      value={currentAddress.surname}
                      onChange={handleInputChange}
                    />
                    <textarea
                      name="address"
                      placeholder="Adres"
                      rows="3"
                      className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      value={currentAddress.address}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      name="city"
                      placeholder="Şehir"
                      className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      value={currentAddress.city}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      name="zipCode"
                      placeholder="Posta Kodu"
                      className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      value={currentAddress.zipCode}
                      onChange={handleInputChange}
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Telefon Numarası"
                      className="w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                      value={currentAddress.phone}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="mt-6 flex justify-end gap-4">
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-white bg-orange-600 rounded-md hover:bg-orange-700 focus:outline-none"
                      onClick={handleAddOrUpdateAddress}
                    >
                      {editingAddress ? 'Güncelle' : 'Ekle'}
                    </button>
                    <button
                      type="button"
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none"
                      onClick={closeModal}
                    >
                      İptal
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
