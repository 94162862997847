import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SearchPage() {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Arama işlemi tetiklendiğinde searchTerm'i ProductListPage'e yönlendirecek
  const handleSearch = () => {
    if (searchTerm.trim()) {
      navigate('/plp', { state: { searchTerm } });
    }
  };

  // Enter tuşu ile aramayı tetikleme
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="container mx-auto py-6">
      <h1 className="text-2xl font-bold mb-4">Ürün Ara</h1>

      {/* Arama Inputu */}
      <div className="flex">
        <input
          type="text"
          className="w-full p-2 border border-gray-300 rounded-lg"
          placeholder="Ürün arayın..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown} // Enter tuşunu dinleyen event
        />
        <button
          className="ml-2 px-4 py-2 bg-blue-500 text-white rounded-lg"
          onClick={handleSearch} // Butona tıklanınca arama yapılır
        >
          Ara
        </button>
      </div>
    </div>
  );
}

export default SearchPage;
