import React from 'react'
import Navbar from './Navbar'
import Footer from './components/Footer'

export default function AboutUs() {
    const stats = [
        { label: 'Founded', value: '2021' },
        { label: 'Employees', value: '37' },
        { label: 'Countries', value: '12' },
        { label: 'Raised', value: '$25M' },
      ]
  return (
<>
<Navbar/>
<div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="lg:pr-4">
         
            </div>
            <div>
              <div className="text-base leading-7 text-gray-700 lg:max-w-lg">
                <p className="text-base font-semibold leading-7 text-indigo-600">Hakkımızda</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                En Özel Parçalarla Karşılaşmak İçin Hazır Olun!
                </h1>
                <div className="max-w-xl">
                  <p className="mt-6">
                  Elmalı Collectibles olarak, hayranı olduğunuz filmlerin büyüsünü hayatınıza taşımak için buradayız. En sevdiğiniz evrenin en beğeneceğiniz favori parçasını bulup sizlerle buluşturmak, bizim motivasyon kaynağımız. En özel ve nadir parçalar, koleksiyonunuza değer katmak için sizi bekliyor!

                  </p>
                  <p className="mt-8">
                  Tutkumuz, sinema, çizgi roman ve pop kültür dünyasının unutulmaz karakterlerini veya farklı evrenlerin en özel ve nadir parçalarını sizleri buluşturmak. Biz sadece bir ürün sunmuyoruz, aynı zamanda her bir parçayla birlikte o karakterin hikayesini de getiriyoruz.

                  </p>
                  <p className="mt-8">
                  Mağazamızda, Marvel, DC, Star Wars, Lord of the Rings, Harry Potter, Dune, Disney ve daha birçok efsanevi serinin aksiyon figürlerini, heykellerini, prop replikalarını ve büstlerini bulabilirsiniz. Koleksiyon dünyasına dair, sınırlı sayıda üretilen parçalar, özel edisyonlar ve gerçek koleksiyon tutkunları için olmazsa olmazları sizlerle buluşturmak için buradayız. Hot Toys, NECA, Sideshow Collectibles, Iron Studios gibi dünya çapında tanınan markalarla ürün yelpazemizi sizler için genişletip, her üründe kaliteyi ve detay zenginliğini garanti ediyoruz.

                  </p>
                  <p className="mt-8">
                  Sizin memnun olmanız bizim için her zaman ön planda. Her koleksiyon parçasını büyük bir titizlikle seçiyor, hızlı kargo ve her bütçeye uygun fiyatlarla sizlere sunuyoruz. Elmalı Collectibles, sadece bir alışveriş platformu değil, aynı zamanda instagram sayfamızda da koleksiyon tutkunlarının bir araya geldiği bir topluluktur. Sizlerle bu tutkuyu paylaşmak, geri bildirimlerinizi dinlemek ve koleksiyon maceralarınıza eşlik etmek bizim için en büyük mutluluk.
                  </p>
                </div>
              </div>

         
            </div>
          </div>
        </div>
      </div>
      <Footer/>
</>

    )
}



  