import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from './firebaseConfig'; // Firebase yapılandırması import edildi
import { doc, getDoc, setDoc, collection } from 'firebase/firestore'; // Firestore fonksiyonları import edildi
import { onAuthStateChanged } from 'firebase/auth'; // Firebase Authentication fonksiyonu import edildi
import Swal from 'sweetalert2'; // SweetAlert2 kütüphanesi import edildi
import Footer from './components/Footer'; // Footer bileşeni import edildi
import DarkNavbar from './DarkNavbar'; // DarkNavbar bileşeni import edildi

// ProductDetail bileşeni, bir ürünün detaylı bilgilerini görüntülemek için kullanılır.
function ProductDetail() {
  // useParams hook'u ile ürün ID'si URL'den alınır.
  const { productId } = useParams();
  // useNavigate hook'u ile başka sayfalara yönlendirme yapılabilir.
  const navigate = useNavigate();
  // product state'i, ürün bilgilerini saklar.
  const [product, setProduct] = useState(null);
  // selectedImage state'i, seçili olan ürün resmini saklar.
  const [selectedImage, setSelectedImage] = useState(null);
  // quantity state'i, sepete eklenecek ürün miktarını saklar.
  const [quantity, setQuantity] = useState(1);
  // isModalOpen state'i, resim modalinin açık olup olmadığını saklar.
  const [isModalOpen, setIsModalOpen] = useState(false);
  // currentImageIndex state'i, modalde görüntülenen resmin indeksini saklar.
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // user state'i, giriş yapmış olan kullanıcının bilgilerini saklar.
  const [user, setUser] = useState(null);
  // thumbnailContainerRef, thumbnail galerisine referans vermek için kullanılır.
  const thumbnailContainerRef = useRef(null);

  // useEffect hook'u, bileşen mount edildiğinde veya productId değiştiğinde ürün bilgilerini Firestore'dan alır.
  useEffect(() => {
    // fetchProduct fonksiyonu, Firestore'dan ürün bilgilerini alır.
    const fetchProduct = async () => {
      try {
        // Firestore'da ürün dokümanına referans alınır.
        const productDoc = doc(db, 'products', productId);
        // Ürün dokümanı Firestore'dan alınır.
        const productSnapshot = await getDoc(productDoc);

        // Ürün dokümanı varsa, state'e atanır.
        if (productSnapshot.exists()) {
          const productData = productSnapshot.data();
          setProduct(productData);
          setSelectedImage(productData.coverImageUrl);
        } else {
          // Ürün dokümanı yoksa, konsola hata mesajı yazdırılır.
          console.error('Ürün bulunamadı!');
        }
      } catch (error) {
        // Ürün alınırken hata oluşursa, konsola hata mesajı yazdırılır.
        console.error('Ürün alınırken hata oluştu:', error);
      }
    };

    // fetchProduct fonksiyonu çağrılır.
    fetchProduct();
  }, [productId]);

  // Firebase Authentication ile kullanıcının giriş yapıp yapmadığı kontrol edilir.
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  // Kapak resmi, product.imageUrls dizisinin başına eklenir.
  const images = product ? [product.coverImageUrl, ...product.imageUrls] : [];

  // handleAddToCart fonksiyonu, ürünü sepete ekler.
  const handleAddToCart = () => {
    // Sepetteki ürünler localStorage'dan alınır.
    const cartItems = JSON.parse(localStorage.getItem('cart')) || [];

    // Ürün sepette varsa, miktarı artırılır.
    const itemExists = cartItems.find((item) => item.id === productId);

    if (itemExists) {
      if (itemExists.quantity >= product.stock) {
        Swal.fire('Hata', 'Stokta yeterli ürün bulunmuyor.', 'error');
        return;
      } else {
        itemExists.quantity += quantity;
      }
    } else {
      // Ürün sepette yoksa, sepete eklenir.
      if (quantity > product.stock) {
        Swal.fire('Hata', 'Stokta yeterli ürün bulunmuyor.', 'error');
        return;
      }
      cartItems.push({ ...product, id: productId, quantity });
    }

    // Güncellenen sepet localStorage'a kaydedilir.
    localStorage.setItem('cart', JSON.stringify(cartItems));
    // Başarılı mesajı gösterilir.
    Swal.fire('Başarılı!', 'Ürün sepete eklendi!', 'success');
  };

  // handleImageClick fonksiyonu, bir resme tıklandığında resim modalini açar.
  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  // handleNextImage fonksiyonu, modalde bir sonraki resmi gösterir.
  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    setSelectedImage(images[(currentImageIndex + 1) % images.length]);
  };

  // handlePrevImage fonksiyonu, modalde bir önceki resmi gösterir.
  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setSelectedImage(images[(currentImageIndex - 1 + images.length) % images.length]);
  };

  // handleCloseModal fonksiyonu, resim modalini kapatır.
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // incrementQuantity fonksiyonu, ürün miktarını artırır.
  const incrementQuantity = () => {
    if (quantity < product.stock) {
      setQuantity(quantity + 1);
    }
  };

  // decrementQuantity fonksiyonu, ürün miktarını azaltır.
  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleAddToFavorites = async () => {
    if (!user) {
      // Kullanıcı giriş yapmamışsa uyarı mesajı göster
      Swal.fire({
        title: 'Giriş Yapın',
        text: 'Bu işlem için giriş yapmanız gerekiyor.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Giriş Yap',
        cancelButtonText: 'İptal',
      }).then((result) => {
        if (result.isConfirmed) {
          // Giriş yap butonuna tıklanırsa /login sayfasına yönlendir
          navigate('/login');
        }
      });
    } else {
      try {
        // Kullanıcı Firestore referansını oluştur
        const userRef = doc(db, 'users', user.uid);
  
        // Kullanıcının mevcut favorilerini çek
        const userDoc = await getDoc(userRef);
        let favorites = [];
        if (userDoc.exists()) {
          favorites = userDoc.data().favorites || [];
        }
  
        // Eğer ürün favorilerde yoksa ekle
        if (!favorites.some(fav => fav.id === productId)) {
          favorites.push({ id: productId });
          await setDoc(userRef, { favorites }, { merge: true });
          Swal.fire('Başarılı!', 'Ürün favorilere eklendi!', 'success');
        } else {
          Swal.fire('Bilgi', 'Bu ürün zaten favorilerde mevcut.', 'info');
        }
      } catch (error) {
        console.error('Favorilere eklenirken hata oluştu:', error);
        Swal.fire('Hata', 'Bir hata oluştu, lütfen tekrar deneyin.', 'error');
      }
    }
  };
  

  // handleNotifyMe fonksiyonu, kullanıcı giriş yapmamışsa /login sayfasına yönlendirir, giriş yapmışsa ürünü wishlist'e ekler.
  const handleNotifyMe = async () => {
    if (!user) {
      // Kullanıcı giriş yapmamışsa uyarı mesajı göster
      Swal.fire({
        title: 'Giriş Yapın',
        text: 'Bu işlem için giriş yapmanız gerekiyor.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Giriş Yap',
        cancelButtonText: 'İptal',
      }).then((result) => {
        if (result.isConfirmed) {
          // Giriş yap butonuna tıklanırsa /login sayfasına yönlendir
          navigate('/login');
        }
      });
    } else {
      try {
        const wishlistRef = collection(db, 'wishlist');
        await setDoc(doc(wishlistRef), {
          userId: user.uid,
          userEmail: user.email,
          productId: productId,
          productName: product.productName,
        });
        Swal.fire('Başarılı!', 'Bu ürün gelince sizlere e-posta ile bilgi verilecektir, lütfen kayıtlı e-postanızı kontrol ediniz.!', 'success');
      } catch (error) {
        console.error('Wishlist kaydı eklenirken hata oluştu:', error);
        Swal.fire('Hata', 'Bir hata oluştu, lütfen tekrar deneyin.', 'error');
      }
    }
  };

  // scrollThumbnails fonksiyonu, thumbnail galerisini kaydırır.
  const scrollThumbnails = (direction) => {
    if (thumbnailContainerRef.current) {
      const scrollAmount = 100;
      thumbnailContainerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  // Ürün bilgileri henüz yüklenmemişse "Yükleniyor..." mesajı gösterilir.
  if (!product) {
    return <p>Yükleniyor...</p>;
  }

  // Ürün bilgileri yüklendiğinde, ürün detayları sayfası render edilir.
  return (
    <>
      <DarkNavbar />

      <section className="py-4 bg-white text-gray-900 md:py-8 xl:py-12 antialiased flex flex-col justify-between min-h-screen">
        <div className="max-w-screen-xl mx-auto flex flex-col lg:flex-row lg:space-x-8 px-4">
          {/* Sol Kısım: Ürün Görseli */}
          <div className="lg:w-1/2 w-full flex flex-col items-center lg:items-start relative">
            <div className="relative w-full max-w-full rounded-lg overflow-hidden mb-4 flex items-center justify-center">
              <div className="relative w-full aspect-w-16 aspect-h-9 max-h-[600px] rounded-lg overflow-hidden">
                <button
                  className="absolute left-2 md:left-4 text-black text-2xl z-10 bg-white p-2 md:p-3 rounded-full shadow-lg hover:bg-gray-200 transition-transform transform hover:scale-110"
                  onClick={handlePrevImage}
                  style={{ top: '50%', transform: 'translateY(-50%)' }}
                >
                  ‹
                </button>
                <img
                  className="w-full h-full object-cover cursor-pointer"
                  src={selectedImage}
                  alt={product.productName}
                  onClick={() => handleImageClick(currentImageIndex)}
                />
                <button
                  className="absolute right-2 md:right-4 text-black text-2xl z-10 bg-white p-2 md:p-3 rounded-full shadow-lg hover:bg-gray-200 transition-transform transform hover:scale-110"
                  onClick={handleNextImage}
                  style={{ top: '50%', transform: 'translateY(-50%)' }}
                >
                  ›
                </button>
              </div>
            </div>

            {/* Thumbnail gallery with scroll and arrows */}
            <div className="relative flex items-center w-full max-w-full overflow-hidden justify-center">
              <button
                className="absolute left-0 text-black text-xl z-10 bg-white p-2 rounded-full shadow-lg hover:bg-gray-200 transition-transform transform hover:scale-110"
                onClick={() => scrollThumbnails('left')}
              >
                ‹
              </button>
              <ul
                ref={thumbnailContainerRef}
                className="flex flex-row space-x-2 lg:space-x-4 overflow-x-auto max-w-[90%] scrollbar-hide snap-x snap-mandatory"
                style={{ scrollSnapType: 'x mandatory' }}
              >
                {images.map((url, index) => (
                  <li
                    key={index}
                    role="presentation"
                    className="snap-center flex-shrink-0"
                    style={{ width: 'calc(100% / 4)' }}
                  >
                    <button
                      className={`h-16 md:h-20 w-full overflow-hidden border-2 rounded-lg p-1 cursor-pointer ${
                        selectedImage === url ? 'border-primary-500' : 'border-gray-200'
                      }`}
                      type="button"
                      onClick={() => {
                        setSelectedImage(url);
                        setCurrentImageIndex(index);
                      }}
                    >
                      <img className="object-cover w-full h-full" src={url} alt={`Ürün Resmi ${index + 1}`} />
                    </button>
                  </li>
                ))}
              </ul>
              <button
                className="absolute right-0 text-black text-xl z-10 bg-white p-2 rounded-full shadow-lg hover:bg-gray-200 transition-transform transform hover:scale-110"
                onClick={() => scrollThumbnails('right')}
              >
                ›
              </button>
            </div>
          </div>

          {/* Sağ Kısım: Ürün Bilgileri */}
          <div className="lg:w-1/2 mt-6 lg:mt-0 flex-shrink-0 px-0 lg:px-4 w-full">
            <h1 className="text-2xl md:text-4xl font-black mb-4 text-center lg:text-left">{product.productName}</h1>

            {/* Fiyat */}
            <div className="mt-4 text-center lg:text-left">
              <p className="text-2xl md:text-3xl font-extrabold text-black">
                {product.discount > 0 ? (
                  <>
                    <span className="line-through text-red-600">{product.price} ₺</span>
                    <span className="ml-2 text-black font-extrabold">
                      {(product.price * (100 - product.discount) / 100).toFixed(2)} ₺
                    </span>
                  </>
                ) : (
                  <span>{product.price} ₺</span>
                )}
              </p>
            </div>
            <div className="mt-6 text-center lg:text-left">
              <h2 className="text-xl md:text-2xl font-bold">Ürün Detayları</h2>
              <p className="text-sm md:text-base">{product.details}</p>
            </div>

            {/* Ürün Özellikleri */}
            <div className="mt-6 text-center lg:text-left">
              <ul className="text-xs md:text-sm text-gray-700 grid grid-cols-2 gap-4 list-disc list-inside">
                <li>
                  <strong>Malzeme:</strong>{' '}
                  {Array.isArray(product.materials) && product.materials.length > 0
                    ? product.materials.join(', ')
                    : product.materials || '-'}
                </li>
                <li>
                  <strong>Ölçek:</strong> {product.scale ? product.scale : '-'}
                </li>
                <li>
                  <strong>Ürün Boyutu:</strong> {product.boyut ? product.boyut : '-'}
                </li>
                <li>
                  <strong>Üretici:</strong>{' '}
                  {Array.isArray(product.manufacturers) && product.manufacturers.length > 0
                    ? product.manufacturers.join(', ')
                    : product.manufacturers || '-'}
                </li>
                <li>
                  <strong>Kategori:</strong>{' '}
                  {Array.isArray(product.categories) && product.categories.length > 0
                    ? product.categories.join(', ')
                    : product.categories || '-'}
                </li>
                <li>
                  <strong>Dünya Limiti:</strong> {product.limit ? product.limit : '-'}
                </li>
              </ul>
            </div>

            {/* Sepete Ekle ve Adet Seçimi */}
            <div className="flex flex-col md:flex-row gap-4 mt-8 md:mt-10 items-center justify-center lg:justify-start">
              <div className="flex items-center space-x-4">
                <button
                  className="px-4 py-2 bg-gray-200 rounded-lg text-lg"
                  onClick={decrementQuantity}
                  disabled={quantity <= 1}
                >
                  -
                </button>
                <span className="text-xl font-bold">{quantity}</span>
                <button
                  className="px-4 py-2 bg-gray-200 rounded-lg text-lg"
                  onClick={incrementQuantity}
                  disabled={quantity >= product.stock}
                >
                  +
                </button>
              </div>

              {product.stock > 0 ? (
                <button
                  onClick={handleAddToCart}
                  className="w-full md:w-auto flex items-center justify-center py-2.5 px-5 text-sm font-medium bg-black text-white rounded-lg hover:bg-gray-900"
                >
                  Sepete Ekle
                </button>
              ) : (
                <button
                  onClick={handleNotifyMe}
                  className="w-full md:w-auto flex items-center justify-center py-2.5 px-5 text-sm font-medium bg-gray-400 text-white rounded-lg"
                >
                  Gelince haber ver
                </button>
              )}
<button
  onClick={handleAddToFavorites}
  className="w-full md:w-auto flex items-center justify-center py-2.5 px-5 text-sm font-medium bg-red-600 text-white rounded-lg hover:bg-red-700"
>
  Favorilere Ekle
</button>

            </div>
          </div>
        </div>

        {/* Resim Modal */}
        {isModalOpen && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-80 flex items-center justify-center">
            <button className="absolute top-4 right-4 text-white text-2xl" onClick={handleCloseModal}>
              ×
            </button>
            <button className="absolute left-4 text-white text-4xl" onClick={handlePrevImage}>
              ‹
            </button>
            <img
              src={images[currentImageIndex]}
              alt={`Ürün Resmi ${currentImageIndex + 1}`}
              className="max-w-4xl max-h-[80vh] object-contain"
            />
            <button className="absolute right-4 text-white text-4xl" onClick={handleNextImage}>
              ›
            </button>
          </div>
        )}
      </section>
      <Footer />
    </>
  );
}

export default ProductDetail;