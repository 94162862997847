import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { collection, doc, getDoc } from 'firebase/firestore';
import ProductCard from '../components/ProductCard';
import Navbar from '../Navbar';
import Swal from 'sweetalert2';
import { onAuthStateChanged } from 'firebase/auth';
import {
  UserCircleIcon,
  MapPinIcon,
  BellIcon,
  HeartIcon,
  ArrowUturnRightIcon,
  CheckCircleIcon,
} from '@heroicons/react/24/outline';

const subNavigation = [
  { name: 'Kişisel Bilgilerim', href: '#', icon: UserCircleIcon, current: false },
  { name: 'Adreslerim', href: '/adres', icon: MapPinIcon, current: false },
  { name: 'Favorilerim', href: '/favorites', icon: HeartIcon, current: true },
  { name: 'Gelince Haber Ver Listem', href: '#', icon: BellIcon, current: false },
  { name: 'Aktif Siparişlerim', href: '#', icon: ArrowUturnRightIcon, current: false },
  { name: 'Geçmiş Siparişlerim', href: '#', icon: CheckCircleIcon, current: false },
];

export default function FavoriteProductsPage() {
  const [favoriteProducts, setFavoriteProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchFavoriteProducts(user.uid);
      } else {
        setLoading(false);
        Swal.fire("Bilgi", "Lütfen favori ürünleri görmek için giriş yapın.", "info");
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const fetchFavoriteProducts = async (userId) => {
    setLoading(true);
    try {
      const userRef = doc(db, 'users', userId);
      const userSnapshot = await getDoc(userRef);

      if (userSnapshot.exists()) {
        const favoriteIds = userSnapshot.data().favorites || [];
        const productDetailsPromises = favoriteIds.map(async ({ id }) => {
          const productRef = doc(collection(db, 'products'), id);
          const productSnapshot = await getDoc(productRef);
          return productSnapshot.exists() ? { id: productSnapshot.id, ...productSnapshot.data() } : null;
        });

        const products = (await Promise.all(productDetailsPromises)).filter(Boolean);
        setFavoriteProducts(products);
      }
    } catch (error) {
      console.error("Favori ürünler alınırken hata oluştu:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status" />
          <p className="mt-3 text-gray-600">Favori ürünler yükleniyor...</p>
        </div>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <div className="h-screen bg-white">
        <main className="mx-auto max-w-7xl pb-10 lg:px-8 lg:py-12">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            {/* Sidebar */}
            <aside className="px-2 py-6 sm:px-6 lg:col-span-3 lg:px-0 lg:py-0">
              <nav className="space-y-1">
                {subNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? 'page' : undefined}
                    className={`${
                      item.current ? 'bg-gray-50 text-orange-600' : 'text-gray-900'
                    } group flex items-center rounded-md px-3 py-2 text-sm font-medium`}
                  >
                    <item.icon
                      aria-hidden="true"
                      className={`${
                        item.current ? 'text-orange-500' : 'text-gray-400'
                      } -ml-1 mr-3 h-6 w-6 flex-shrink-0`}
                    />
                    <span className="truncate">{item.name}</span>
                  </a>
                ))}
              </nav>
            </aside>

            {/* Content Area */}
            <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
              <section aria-labelledby="favorite-products-heading">
                <h2 id="favorite-products-heading" className="text-lg font-medium text-gray-900">
                  Favori Ürünlerim
                </h2>
                {favoriteProducts.length > 0 ? (
                  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-6">
                    {favoriteProducts.map((product) => (
                      <ProductCard key={product.id} product={product} />
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-500 mt-4">Henüz favori ürün eklemediniz.</p>
                )}
              </section>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
