import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { db, auth } from './firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

const Checkout = () => {
  const location = useLocation();
  const { totalPrice, cartItems } = location.state || {
    totalPrice: 0,
    cartItems: [],
  };

  const [addresses, setAddresses] = useState([]);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [buyerInfo, setBuyerInfo] = useState({
    identityNumber: '11111111111',
    name: '',
    surname: '',
    email: '',
    address: '',
    city: '',
    zipCode: '',
    country: 'Türkiye',
    phone: '',
    userId: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isGuest, setIsGuest] = useState(true); // Varsayılan olarak misafir olarak ayarla

  useEffect(() => {
    const fetchAddresses = async () => {
      const user = auth.currentUser;
      if (user) {
        setIsGuest(false);
        const addressRef = collection(db, `users/${user.uid}/addresses`);
        const addressSnapshot = await getDocs(addressRef);
        const addressList = addressSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAddresses(addressList);
        setBuyerInfo((prev) => ({
          ...prev,
          email: user.email,
          userId: user.uid,
        }));
        if (addressList.length > 0) {
          setSelectedAddressId(addressList[0].id);
          updateBuyerInfo(addressList[0]);
        }
      }
    };
    fetchAddresses();
  }, []);

  const updateBuyerInfo = (address) => {
    setBuyerInfo((prev) => ({
      ...prev,
      name: address.name,
      surname: address.surname,
      address: address.address,
      city: address.city,
      zipCode: address.zipCode,
      phone: address.phone,
    }));
  };

  const handleAddressChange = (e) => {
    const selectedId = e.target.value;
    setSelectedAddressId(selectedId);
    const selectedAddress = addresses.find((address) => address.id === selectedId);
    if (selectedAddress) {
      updateBuyerInfo(selectedAddress);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBuyerInfo((prev) => ({ ...prev, [name]: value }));
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
  
    try {
      const user = auth.currentUser;
      if (user) {
        // Ürün ID'lerini logla
        const itemIds = cartItems.map((item) => item.id);
        console.log("Listed Product IDs:", itemIds);
  
        const response = await fetch('https://elmalicback.vercel.app/api/iyzico-payment', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            totalPrice: Number(totalPrice).toFixed(2),
            items: cartItems.map((item) => ({
              id: item.id,
              name: item.productName,
              price: (
                item.discount > 0
                  ? (item.price * (100 - item.discount)) / 100
                  : item.price
              ).toFixed(2),
              quantity: item.quantity,
            })),
            buyer: {
              ...buyerInfo,
              userId: user.uid, // UID'yi ekleyin
            },
          }),
        });
  
        const data = await response.json();
        if (data.paymentPageUrl) {
          window.location.href = data.paymentPageUrl;
        } else {
          setError("Ödeme sayfası yüklenemedi. Lütfen tekrar deneyin.");
        }
      } else {
        setError("Kullanıcı kimliği doğrulanamadı.");
      }
    } catch (error) {
      setError("Ödeme işlemi sırasında bir hata oluştu.");
    } finally {
      setLoading(false);
    }
  };
  
  
  

  return (
    <div className="checkout-container bg-gray-100 min-h-screen flex justify-center items-center py-6">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">Ödeme Sayfası</h1>
        <h3 className="text-xl font-semibold mb-4 text-center">Toplam Tutar: {totalPrice}₺</h3>

        {error && <p className="text-red-500 text-center mb-4">{error}</p>}

        <form onSubmit={handleFormSubmit} className="space-y-6">
          {isGuest ? (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700">Ad</label>
                <input
                  type="text"
                  name="name"
                  value={buyerInfo.name}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Soyad</label>
                <input
                  type="text"
                  name="surname"
                  value={buyerInfo.surname}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Adres</label>
                <input
                  type="text"
                  name="address"
                  value={buyerInfo.address}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Şehir</label>
                <input
                  type="text"
                  name="city"
                  value={buyerInfo.city}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Posta Kodu</label>
                <input
                  type="text"
                  name="zipCode"
                  value={buyerInfo.zipCode}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Telefon</label>
                <input
                  type="tel"
                  name="phone"
                  value={buyerInfo.phone}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={buyerInfo.email}
                  onChange={handleInputChange}
                  className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  required
                />
              </div>
            </>
          ) : (
            <div>
              <label className="block text-sm font-medium text-gray-700">Teslimat Adresinizi Seçin</label>
              <select
                className="mt-1 p-2 w-full border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                value={selectedAddressId || ''}
                onChange={handleAddressChange}
                required
              >
                {addresses.map((address) => (
                  <option key={address.id} value={address.id}>
                    {`${address.name} ${address.surname} - ${address.address}, ${address.city}, ${address.zipCode}`}
                  </option>
                ))}
              </select>
            </div>
          )}
          <button
            type="submit"
            className="w-full py-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition"
            disabled={loading}
          >
            {loading ? 'Yükleniyor...' : 'Ödemeye Geç'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Checkout;
