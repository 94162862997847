import React from 'react';
import Navbar from './Navbar';

const SalesAgreement = () => {
  return (
    <>
      <Navbar />
      <div className="bg-gray-100 min-h-screen py-10 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
          <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">
            ELMALI COLLECTIBLES MESAFELİ SATIŞ SÖZLEŞMESİ
          </h1>

          <div className="space-y-8 text-gray-700">
            <section>
              <h2 className="text-xl font-semibold">1. TARAFLAR</h2>
              <p className="mt-2">
                İşbu Sözleşme aşağıdaki taraflar arasında aşağıda belirtilen hüküm ve şartlar çerçevesinde imzalanmıştır.
              </p>
              <p className="mt-2">
                <strong>ALICI</strong> (sözleşmede bundan sonra “ALICI” olarak anılacaktır): <br />
                AD - SOYAD: <br />
                TESLİMAT ADRES: <br />
                TELEFON: <br />
                E-POSTA: <br />
                <br />
                <strong>SATICI</strong> (sözleşmede bundan sonra “Elmalı Collectibles” olarak anılacaktır): <br />
                TİCARİ ÜNVAN: Elmalı Collectibles <br />
                ADRES: Onur Mahallesi Limon Sokak No 1/73 Duru Plaza İş Merkezi Balçova / İZMİR <br />
                E-posta adresi: elmalicollectibles@gmail.com <br />
                Ticaret Sicil No: 540845 <br />
                Vergi Dairesi: BALÇOVA <br />
                Vergi No: 3320732456 <br />
                Ürün iade adresi: Onur Mahallesi Limon Sokak No 1/73 Duru Plaza İş Merkezi Balçova / İZMİR
              </p>
              <p className="mt-2">
                İş bu sözleşmeyi kabul etmekle ALICI, sözleşme konusu siparişi onayladığı takdirde sipariş konusu bedeli ve varsa kargo ücreti, vergi gibi belirtilen ek ücretleri ödeme yükümlülüğü altına gireceğini ve bu konuda bilgilendirildiğini peşinen kabul eder.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold">2. SÖZLEŞMENİN KONUSU</h2>
              <p className="mt-2">
                Alıcının, Elmalı Collectibles’a ait elmalicollectibles.com internet sitesinden elektronik ortamda siparişini verdiği, özellikleri ve satış fiyatı bu metinde belirtilen ürünün satışı, bedelinin ödenmesi ve teslimine ilişkin olarak 6502 Sayılı Tüketicinin Korunması Hakkında Kanun ve Mesafeli Sözleşmeler Yönetmeliği’ne göre tarafların hak ve yükümlülüklerinin belirlenmesidir.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold">3. Sözleşme Konusu Ürünlerin Temel Özellikleri ve Fiyatlar</h2>
              <p className="mt-2">
                3.1. Sözleşme konusu ürünlerin adı, temel özellikleri, cinsi ve türü, miktarı, marka ve modeli, rengi, vergiler ve nakliye bedeli (adet x birim fiyat olarak) bu metinde belirtildiği gibidir. Bu bedellere başkaca herhangi bir ilave olmayacaktır. Alıcının, kredi kartının sağladığı taksit imkanlarından faydalanması halinde ortaya çıkacak faiz ve ücretlerin ürün bedeliyle ve Elmalı Collectibles’le hiçbir ilgisi yoktur.
              </p>
              <p className="mt-2">3.2. Detaylı bilgilere elmalicollectibles.com adresinden ulaşılabilecektir.</p>
            </section>

            <section>
              <h2 className="text-xl font-semibold">4. Geçerlilik Süresi</h2>
              <p className="mt-2">
                Belirtilen fiyatlar, sözleşme taraflarca ifa olunana veya feshedilene kadar geçerlidir.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold">5. Ödemeler ve Teslimat</h2>
              <p className="mt-2">
                5.1. Ödeme sipariş aşamasında kredi kartı ve havale/eft ile yapılacaktır. <br />
                5.2. Sipariş işlemi ödemenin yapılması ile tamamlanmış sayılır. Sipariş, ödeme işlemi tamamlandıktan sonra en kısa sürede anlaşmalı kargo şirketiyle alıcının işbu sözleşme kapsamında beyan ettiği adrese gönderilecektir. <br />
                5.3. Elmalı Collectibles, sözleşme konusu ürünün sağlam, eksiksiz, siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri ve kullanım kılavuzları ile teslim edilmesinden sorumludur. <br />
                5.4. Teslim anında Alıcı’nın adresinde bulunmaması durumunda Elmalı Collectibles edimini yerine getirmiş olarak kabul edilecektir. Bu nedenle, Alıcı’nın ürünü geç teslim almasından kaynaklanan her türlü zarar ile ürünün kargo şirketinde beklemiş olması ve/veya kargonun Elmalı Collectibles’a geri iade edilmesinden dolayı oluşan giderler de Alıcı’ya aittir. <br />
                5.5. Aynı şekilde, sözleşme konusu ürün, Alıcı’dan başka bir kişi/kuruluşa teslim edilecek ise teslim edilecek kişi/kuruluşun teslimatı kabul etmemesinden Elmalı Collectibles sorumlu tutulamaz. <br />
                5.6.   Teslimat aşağıdaki bilgiler çerçevesinde yapılacaktır.: <br />
                5.6.1. Teslimat adresi : <br />
                5.6.2.  Teslim edilecek kişi : <br />
                5.6.3.  Fatura adresi  : <br />




              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold">6. Garanti ve Montaj</h2>
              <p className="mt-2">
                6.1. Alıcı satın aldığı ürünün –gerekmesi halinde- montajını kendisinin yapacağını ve bu montaj sırasında ürünün zarar görmesi halinde bu zarardan kendisinin sorumlu olacağını kabul etmektedir. <br />
                6.2. Sözleşme konusu ürünlerin (cayma hakkı süresi geçtikten sonra) arızalanması veya üründe açık veya gizli ayıpların ortaya çıkması ve alıcının talep etmesi halinde ürünler, yetkilisi servislere gönderilecektir.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold">7. Cayma Hakkı</h2>
              <p className="mt-2">
                7.1. Alıcı, sözleşme konusu ürünün belirttiği adrese tesliminden itibaren 14 (on dört) gün içinde (aşağıda belirtilen istisnalar çerçevesinde) herhangi bir gerekçe göstermeksizin ve cezai şart ödemeksizin, hiçbir hukuki ve cezai sorumluluk üstlenmeksizin cayma hakkına sahiptir. <br />
                7.2. Elmalı Collectibles, alıcının cayma hakkını kullandığına ilişkin bildirimin kendisine ulaştığı tarihten itibaren 14 (on dört) gün içinde alıcıdan tahsil edilen ödemeyi iade eder. <br />
                7.3. Cayma ve iade bildirimi, belirtilen süre içinde elmalicollectibles@gmail.com e-posta adresine iletilecektir. <br />
                7.4. Alıcı, cayma hakkını kullandığına ilişkin bildirimi Elmalı Collectibles’e yönelttiği tarihten itibaren 10 (on) gün içinde iade edeceği ürünleri kendisine ürünlerle birlikte iletilen sevk irsaliyesinde yazılı firmaya ve firmanın adresine göndermek zorundadır. <br />
                7.5. Ürünler, ürünlerin tüketiciye gönderildiği kargo firması ile ücretsiz olarak iade edilebilir/gönderilebilir. Başka bir kargo şirketinin tercih edilmesi durumunda kargo ücreti alıcı tarafından karşılanır. <br />
                7.6. Ürün iade edilmeden bedel iadesi yapılmaz. <br />
                7.7. Cayma hakkının kullanılması halinde, 395 Sayılı Vergi Usul Kanunu uyarınca, iade işlemlerinin yapılabilmesi için alıcıya gönderilen faturadaki ve ürün iade formundaki ilgili bölümlerin eksiksiz olarak doldurulması ve imzalandıktan sonra Elmalı Collectibles’a (ürünlerle birlikte) gönderilmesi gerekmektedir. Ancak, KKTC ve yurtdışı siparişlerinde alıcı KDV ödemediği için, gümrükte almış oldukları ürünlerin vergilerini ödemekle yükümlüdür.
                7.8. İade işlemlerinin sağlıklı, sorunsuz ve süresinde yapılabilmesi için alıcı, cayma bildirimi ve iade talepleriyle birlikte takip numarasını da bildirmekle yükümlüdür. Elmalı Collectibles, takip numarası bildirilmeyen iade işlemlerindeki gecikmelerden ve aksamalardan sorumlu değildir. <br />
                7.9. Cayma hakkı kapsamında iade edilecek ürünler, varsa hediyesi, tüm aksesuarları, kutusu ve orijinal paketiyle birlikte eksiksiz ve hasarsız olarak Elmalı Collectibles’e gönderilmelidir.

              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold">8. Cayma ve Ürün İade Hakkının İstisnaları</h2>
              <p className="mt-2">
                8.1. KKTC ve yurtdışı siparişlerinde, her ne şekilde olursa olsun gümrükten teslim alınmadığı için alıcıya teslim edilememiş olan ürünler için alıcıların cayma hakkı bulunmamaktadır. <br />
                8.2. Alıcının elindeyken, alıcının kusuruyla hasar görmüş ürünlerle ilgili cayma hakkı kullanılamaz. <br />
                8.3. Aşağıdaki durumlarda cayma hakkının kullanılması mümkün değildir: <br />
                a) Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve Elmalı Collectibles’ın kontrolünde olmayan ürünlere ilişkin sözleşmeler. <br />
                b) Alıcının istekleri veya kişisel ihtiyaçları doğrultusunda hazırlanan mallara ilişkin sözleşmeler. <br />
                c) Çabuk bozulabilen veya son kullanma tarihi geçebilecek malların teslimine ilişkin sözleşmeler. <br />
                ç) Tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olan mallardan; iadesi sağlık ve hijyen açısından uygun olmayanların teslimine ilişkin sözleşmeler. <br />
                d) Tesliminden sonra başka ürünlerle karışan ve doğası gereği ayrıştırılması mümkün olmayan mallara ilişkin sözleşmeler. <br />
                e) Malın tesliminden sonra ambalaj, bant, mühür, paket gibi koruyucu unsurları açılmış olması halinde maddi ortamda sunulan kitap, dijital içerik ve bilgisayar sarf malzemelerine ilişkin sözleşmeler. <br />
                f) Abonelik sözleşmesi kapsamında sağlananlar dışında, gazete ve dergi gibi süreli yayınların teslimine ilişkin sözleşmeler. <br />
                g)Belirli bir tarihte veya dönemde yapılması gereken, konaklama, eşya taşıma, araba kiralama, yiyecek-içecek tedariki ve eğlence veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin sözleşmeler. <br />
                ğ) Elektronik ortamda anında ifa edilen hizmetler veya alıcıya anında teslim edilen gayrimaddi mallara ilişkin sözleşmeler. <br />
                h) Cayma hakkı süresi sona ermeden önce, alıcının onayı ile ifasına başlanan hizmetlere ilişkin sözleşmeler. <br /><br />
                8.4.   Cayma hakkı istisnaları kapsamındaki ürünlerin iade edilmesi durumunda, Elmalı Collectibles  birimlerine ulaşan ürünlerin, ambalaj, bant, mühür, paket gibi koruyucu unsurlarının açılmış olduğunun tespit edilmesi halinde bu durumdaki ürünlerin bedelleri, toplam ürün bedelinden mahsup edilerek iade yapılır. Şayet başka ürün yoksa herhangi bir bedel iadesi yapılmaz ve ürün alıcıya geri gönderilir.<br />
                8.5.   Elmalı Collectibles birimlerine ulaşan ürünlerin eksik olup olmadığı, aynı şekilde ürünlerle birlikte gönderilen faturaya veya forma uygun olup olmadığı ve ulaşan ürünlerin durumu kargo ambalajlarının açılması aşamasında tutanakla tespit edilir. İade ve ödeme şartları da bu tutanağa göre belirlenir.
<br />
8.6.   Alıcının cayma ve ürün iade hakkını kullanması halinde, yukarıda belirtilen şekilde düzenlenmiş faturayla birlikte Elmalı Collectiblas’a ulaşan iade ürün, ilgili mevzuat ve bu sözleşmede belirtilen koşullar çerçevesinde geçerli bir iade olarak kabul edilir. Bu durumda Elmalı Collectibles, en geç 10 (on) gün içerisinde almış olduğu toplam bedeli alıcıya hiçbir masraf yüklemeden iade edecektir.
 <br />
 8.7.   Ürün bedelinin kredi kartı ile ödendiği durumlarda iade de kredi kartına yapılır. Bu iade, ürün bedelinin ödenmesi aşamasındaki gibi (aynı miktarda ve taksitlerde) yapılır. Bu iadenin kredi kartı hesaplarına yansıma süresi ilgili bankanın tasarrufundadır ve Elmalı Collectibles bu hususta herhangi bir şekilde sorumlu tutulamaz.
 <br />

 8.8.   İadeye ilişkin detaylı bilgilere ve belgelere elmalicollectibles.com üzerinden ulaşılabilir ve alıcının bilgisayarına indirilebilir.



              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold">9. Kişisel Verilerin Korunmasına İlişkin Hükümler</h2>
              <p className="mt-2">
              9.1.     Alıcı, Elmalı Collectibles’le paylaştığı kişisel verilerinin; Elmalı Collectibles ve tedarikçiler tarafından sunulan ürün ve hizmetlerin belirlenen yasal çerçevede sunulabilmesi veElmalı Collectibles ve tedarikçilerinin sözleşme ve yasadan doğan sorumluluklarını eksiksiz ve doğru şekilde yerine getirebilmesi, alıcının hukuki ve ticari güvenliğinin temini, sunulan ürün ve hizmetlerin alıcının beğeni, alışkanlık ve ihtiyaçlarına göre özelleştirilerek sunulabilmesi, keza alıcıya sunulan hizmet kalitesinin artırılması, Elmalı Collectibles’in ticari ve iş stratejilerinin belirlenmesi, uygulanması, insan kaynakları politikalarının belirtilenmesi amacıyla, Elmalı Collectibles tarafından elmalıcollectibles.com adresli internet sitesi vasıtasıyla veya çağrı merkezi veya tedarikçileri vasıtasıyla sözlü olarak, otomatik veya otomatik olmayan yöntemlerle, 6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 5. ve 6. maddelerinde belirtilen şartlar ve amaçlar dahilinde toplandığını/toplanacağını, işlendiğini/işleneceğini bildiğini kabul ve beyan eder.
<br />
9.2.Diğer yandan alıcı, Elmalı Collectibles  bünyesinde toplanan kişisel verilerinin, bir üst maddede belirtilen amaçlarla Elmalı Collectibles’in iş ortaklarıyla, tedarikçileriyle, Elmalı Collectibles’in grup şirketleriyle, kanunen yetkili kamu kurumları ve özel kişilerle, Kişisel Verilerin Korunması Hakkındaki Kanunu’nun 8. ve 9. maddelerinde şartlarda paylaşıldığını/paylaşılabileceğini bildiğini kabul, beyan ve muvafakat eder. <br/>
9.3.Son olarak alıcı, kişisel verilerinin işlenip işlenmediğini öğrenme ve kişisel verileri işlenmişse bu konuda bilgi talep etme, kişisel verilerinin işlenme amacı ve bunların amacına uygun kullanılıp kullanılmadığını, verilerinin kiminle paylaşıldığını bilme/öğrenme, kişisel verilerinin eksik veya yanlış işlenmiş olması halinde bunların düzeltilmesini isteme, KİŞİSEL VERİLERİNİN SİLİNMESİNİ VE YOK EDİLMESİNİ ve bu işlemin verilerin aktarıldığı üçüncü kişilere bildirilmesini İSTEME ve kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme ve işlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme haklarına sahip olduğunu, bu konudaki tüm beyan ve taleplerini BU BELGEDE YAZILI İLETİŞİM YOLLARINDAN BİRİYLE İLETMESİ HALİNDE talebinin en geç 30 gün içinde neticelendirileceğini bildiğini, kabul ve beyan eder.

              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold">10. Diğer Hususlar ve Teyitler</h2>
              <p className="mt-2">
                10.1. Ön bilgilendirme, işbu mesafeli satış sözleşmesi ve ürünlere ilişkin fatura bir bütündür ve mesafeli satış sözleşmesinin ayrılmaz parçalarıdır. <br />
                10.2. Bu kapsamda alıcı elmalıcollectibles.com internet sitesinde sözleşme konusu ürünün temel nitelikleri, satış fiyatı ve ödeme şekli ile teslimata ilişkin ön bilgileri okuyup bilgi sahibi olduğunu ve elektronik ortamda gerekli teyidi verdiğini beyan eder.   <br />           
                10.3. Elmalı Collectibles, sözleşmeden doğan ifa yükümlülüğünü, süresi dolmadan alıcıyı bilgilendirmek suretiyle eşit kalite ve fiyatta farklı bir ürünle karşılayabilir.
 <br />
 10.4. Kargo firmasının, ürünü alıcıya geç teslim etmesinden Elmalı Collectibles sorumlu tutulamaz.
 <br />
 10.5. Kredi kartından yapılan tahsilatın herhangi bir nedenle Elmalı Collectibles’e ödememesi veya iptal edilmesi halinde Elmalı Collectibles, ürün teslim etmek zorunda olmadığı gibi şayet ürün teslim edilmişse alıcı ürünü 3 gün içinde Elmalı Collectibles‘e iade etmek zorundadır. Bu durumda kargo giderleri alıcıya aittir.
 <br />
 10.6.  Elmalı Collectibles, herhangi bir nedenle sözleşme konusu ürünü süresi içinde teslim edemez ise durumu alıcıya bildirmekle yükümlüdür. Bu takdirde alıcı, ilgili mevzuat çerçevesinde Elmalı Collectibles’e karşı seçimlik haklarını kullanabilir. <br />
10.7. Alıcı, sözleşmenin kurulması aşamasında verdiği bilgilerin doğru olduğunu, sipariş vermeye/sözleşme yapmaya ehil olduğunu ve beyanlarında herhangi bir eksiklik bulunmadığını beyan eder.
 <br />
 10.8. Alıcı, ürünler kendisine teslim edildiğinde şayet kargo paketinin veya ürünlerin zarar gördüğünü tespit ederse, kargo paketini almayarak tutanak tutturmalı ve paketi görevliye iade etmelidir. Kargo paketinin kayıtsız, şartsız ve itirazsız alındığı durumlarda kargonun ve ürünlerin sağlam bir şekilde teslim edildiği kabul edilir.
<br />
10.9. Alıcı bu sözleşme ile; bu sözleşmeden önce ön bilgilendirmeyi ve elmalicollectibles.com adresli internet sitesinde sözleşme konusu ürünün temel niteliklerini okuduğunu, incelediğini ve bilgi sahibi olduğunu, aynı şekilde Elmalı Collectibles tarafından alıcıya, verilmesi gereken adres, siparişi verilen ürünlere ait temel özellikler, ürünlerin vergiler dâhil fiyatı, ödeme ve teslimat bilgilerinin de doğru ve eksiksiz olarak verildiğini, kendisinin bu konularda bilgilendiğini ve bu hususlarla ilgili olarak elektronik ortamda gerekli teyidi verdiğini beyan eder.

                
                </p>

            </section>

            <section>
              <h2 className="text-xl font-semibold">11. Şikayet ve İtiraz Mercileri</h2>
              <p className="mt-2">
              11.1.     Sözleşme konusu ürün ve işlemlerle ilgili şikayet ve itirazlar,  elmalicollectibles@gmail.com şeklindeki mail adresine yapılabilecektir. <br/>
              11.2.     Alıcı,  6502 Sayılı Tüketicinin Korunması Hakkında Kanun’un 68. maddesi çerçevesinde belirlenen limitler doğrultusunda il/ilçe Tüketici Hakem Heyetine veya Tüketici Mahkemesine yapabilecektir.
 <br/>
 11.3.     Sözleşmenin kurulduğu tarih bu sözleşme altında yazan tarih veya internet üzerinden işlem yapıldığı tarihtir.
 <br/>
 11.4.     Sözleşmenin bir örneği alıcıya mail yoluyla gönderileceği gibi alıcı bu metne elmalicollectibles.com  adresli web sitesinden de ulaşabilecektir.
 <br/>
 Bu satış sözleşmesinde belirtilen bilgileri verdiğimizi ve bu surette anlaştığımızı kabul, beyan ve taahhüt ederiz.


              </p>
            </section>

            <div className="mt-10 border-t pt-6">
  <div className="flex justify-between">
    <div className="w-1/2 text-left">
      <p className="text-lg font-semibold">Tarih: …./…../…….</p>
      <p className="mt-4">
        <span className="font-semibold">Alıcı:</span>
        <br />
       ___________________________
        <br />
      </p>
    </div>

    <div className="w-1/2 text-right">
      <p className="mt-4">
        <span className="font-semibold">Satıcı:</span>
        <br />
        Elmalı Collectibles
        <br />
      </p>
    </div>
  </div>
</div>

          </div>
        </div>
      </div>
    </>
  );
};

export default SalesAgreement;
