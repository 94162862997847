import React from 'react';
import { Link } from 'react-router-dom';

export default function Logos() {
  return (
    <div className="py-10">
      <div className="mx-auto mb-10 mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-6">
        <Link to={{ pathname: "/plp", state: { universe: "Marvel" } }}>
          <img
            alt="Marvel"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Marvel_Logo.svg/1600px-Marvel_Logo.svg.png"
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          />
        </Link>
        <Link to={{ pathname: "/plp", state: { universe: "DC" } }}>
          <img
            alt="DC"
            src="https://upload.wikimedia.org/wikipedia/commons/3/3d/DC_Comics_logo.svg"
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          />
        </Link>
        <Link to={{ pathname: "/plp", state: { universe: "Harry Potter" } }}>
          <img
            alt="Harry Potter"
            src="https://firebasestorage.googleapis.com/v0/b/elmalicollectibles-87dc4.appspot.com/o/c4aa94d7-fef8-474f-8dfc-4aecb848fdb9.png?alt=media&token=51b6a4a5-e1d2-4230-8c71-6761bab8a074"
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
          />
        </Link>
        <Link to={{ pathname: "/plp", state: { universe: "LOTR" } }}>
          <img
            alt="LOTR"
            src="https://upload.wikimedia.org/wikipedia/commons/b/b0/Tloftr-logo.svg"
            width={158}
            height={48}
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1"
          />
        </Link>
        <Link to={{ pathname: "/plp", state: { universe: "Star Wars" } }}>
          <img
            alt="Star Wars"
            src="https://upload.wikimedia.org/wikipedia/commons/6/6c/Star_Wars_Logo.svg"
            width={158}
            height={48}
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
          />
        </Link>
        <Link to={{ pathname: "/plp", state: { universe: "Dune" } }}>
          <img
            alt="Dune"
            src="https://upload.wikimedia.org/wikipedia/commons/4/44/Dune_2021_transparent_logo.png"
            width={158}
            height={48}
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
          />
        </Link>
      </div>
    </div>
  );
}