import React, { useState, useEffect, useRef, Fragment } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { auth } from './firebaseConfig';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { UserIcon, MagnifyingGlassIcon, ShoppingCartIcon } from '@heroicons/react/24/outline';

function Navbar() {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  
  const [menuOpen, setMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [cartItemCount, setCartItemCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchModalOpen, setSearchModalOpen] = useState(false); // Arama modal state
  const navigate = useNavigate();
  const handleProfileRedirect = () => {
    navigate('/profile'); // /profile sayfasına yönlendiriyoruz
  };
  const dropdownRef = useRef(null);


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  // Fetch cart items count from local storage
  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cart')) || [];
    setCartItemCount(storedCartItems.length);
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setUserMenuOpen(false); // Menü dışına tıklanırsa menüyü kapat
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);
  
  const toggleUserMenu = (e) => {
    e.stopPropagation(); // Tıklama olayının diğer elemanlara yayılmasını durduruyoruz
    setUserMenuOpen(!userMenuOpen); // Kullanıcı menüsünü aç/kapat
  };
  


  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setUser(null);
      })
      .catch((error) => {
        console.error('Çıkış yapma hatası:', error);
      });
  };

  

  // Arama fonksiyonu
  const handleSearch = () => {
    if (searchTerm.trim()) {
      navigate('/plp', { state: { searchTerm } });
      setSearchTerm(''); // Arama sonrası arama terimini temizleyelim
    }
  };

  // Enter tuşu ile aramayı tetikleme
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
    <div className="flex items-center gap-x-6 bg-gray-900 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
      <p className="text-sm/6 text-white">
          <strong className="font-semibold">1750₺ ve üzeri ücretsiz kargo!</strong>
          <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
            <circle r={1} cx={1} cy={1} />
          </svg>
          Tüm ürünlerde taksit imkanı&nbsp;<span aria-hidden="true"></span>
      </p>
      <div className="flex flex-1 justify-end">
      </div>
    </div>
      <nav className="bg-white antialiased shadow-md">
        <div className="max-w-screen-xl px-4 mx-auto 2xl:px-0">
          <div className="flex items-center justify-between py-4 lg:py-6">
            {/* Mobil Menu Butonu */}
            <button
              onClick={toggleMenu}
              className="inline-flex items-center bg-red-600 lg:hidden p-2 text-white hover:bg-gray-100 rounded-lg"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
              >
                {menuOpen ? (
                  <path d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path d="M4 6h16M4 12h16M4 18h16"></path>
                )}
              </svg>
            </button>
            <ul className={`hidden lg:flex items-center justify-start gap-6 md:gap-8 py-3 sm:justify-center`}>
              <li>
                <Link to="/plp" className="flex text-sm font-medium text-gray-900 hover:text-primary-700">
                  Tüm Ürünler
                </Link>
              </li>
              <li>
                <Link to="/takas" className="flex text-sm font-medium text-gray-900 hover:text-primary-700">
                  Ön Siparişler
                </Link>
              </li>
              <li className="shrink-0 hidden sm:flex">
                <Link to="/takas" className="text-sm font-medium text-gray-900 hover:text-primary-700">
                  Takas
                </Link>
              </li>
            </ul>

            {/* Logo */}
            <div className="flex-grow flex justify-center lg:justify-center">
              <Link to="/" title="">
                <img
                  className="block w-auto h-12 lg:h-20"
                  src="https://firebasestorage.googleapis.com/v0/b/elmalicollectibles-87dc4.appspot.com/o/eclogo.svg?alt=media&token=90d0a55e-00e8-4ef0-a575-d0902091b4c0"
                  alt="Logo"
                />
              </Link>
            </div>

            {/* Mobil İkonlar */}
            <div className="lg:hidden flex items-center">
              <div className="relative">
                <Link to="/cart" className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg">
                  <span className="sr-only">Sepet</span>
                  <ShoppingCartIcon className="w-6 h-6" />
                  {cartItemCount > 0 && (
                    <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-medium text-white bg-red-700 rounded-full -top-1.5 -right-1.5">
                      {cartItemCount}
                    </div>
                  )}
                </Link>
              </div>

              {/* Magnifying Glass Icon */}
              <button
                onClick={() => setSearchModalOpen(true)} // Modal açma
                className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg"
              >
                <MagnifyingGlassIcon className="w-6 h-6" /> {/* Heroicons'dan MagnifyingGlassIcon kullanıldı */}
              </button>

              <div className="relative" ref={dropdownRef}>
                {user ? (
                  <button
                    onClick={handleProfileRedirect}
                    className="inline-flex items-center text-gray-900 p-2 hover:bg-gray-100 rounded-lg"
                  >
                    <UserIcon className="w-6 h-6" />
                  </button>
                ) : (
                  <Link to="/login" className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg">
                    <UserIcon className="w-6 h-6" />
                  </Link>
                )}
              </div>
            </div>

            {/* Sağ İkonlar: Kullanıcı */}
            <div className="hidden lg:flex items-center space-x-4">
              {/* User Menu */}
              <div className="relative" ref={dropdownRef}>
  {user ? (
    <button
      onClick={toggleUserMenu}
      className="inline-flex items-center text-gray-900 p-2 hover:bg-gray-100 rounded-lg"
    >
      <UserIcon className="w-6 h-6" />
      <span className="hidden lg:flex ml-2">Hesabım</span>
    </button>
  ) : (
    <Link
      to="/login"
      className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg"
    >
      <UserIcon className="w-6 h-6" />
      <span className="hidden lg:flex ml-2">Giriş Yap / Kayıt Ol</span>
    </Link>
  )}

  {/* Kullanıcı Menüsü */}
  {userMenuOpen && user && (
    <div className="absolute top-full mt-2 w-48 bg-white rounded-md shadow-lg z-50">
      <Link to="/profile" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
        Profilim
      </Link>
      <Link to="/favorites" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
        Favorilerim
      </Link>
      <Link to="/orders" className="block px-4 py-2 text-gray-700 hover:bg-gray-100">
        Siparişlerim
      </Link>
      <button
        onClick={handleSignOut}
        className="block w-full text-left px-4 py-2 text-gray-700 hover:bg-gray-100 border-t-2"
      >
        Çıkış Yap
      </button>
    </div>
  )}
</div>


              {/* Cart Icon */}
              <div className="relative">
                <Link to="/cart" className="inline-flex items-center p-2 text-gray-900 hover:bg-gray-100 rounded-lg">
                  <span className="sr-only">Sepet</span>
                  <ShoppingCartIcon className="w-6 h-6" />
                  <span className="hidden lg:flex ml-2">Sepetim</span>
                  {cartItemCount > 0 && (
                    <div className="absolute inline-flex items-center justify-center w-4 h-4 text-xs font-medium text-white bg-red-700 rounded-full -top-1.5 -right-1.5">
                      {cartItemCount}
                    </div>
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Hamburger Menüsü Tam Ekran */}
        {menuOpen && (
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 z-50 flex flex-col justify-center items-center">
            <button onClick={closeMenu} className="absolute top-4 right-4 text-white text-4xl">
              &times;
            </button>
            <div className="flex flex-col items-center space-y-6">
              <Link to="/plp" onClick={closeMenu} className="text-white text-2xl font-semibold">
                Ürünler
              </Link>
              <Link to="/takas" onClick={closeMenu} className="text-white text-2xl font-semibold">
                Ön Siparişler
              </Link>
              <Link to="/takas" onClick={closeMenu} className="text-white text-2xl font-semibold">
                Takas
              </Link>
              <Link to="/cart" onClick={closeMenu} className="text-white text-2xl font-semibold">
                Sepetim
              </Link>

              {/* Mobil Arama Çubuğu */}
              

              {user ? (
                <>
                  <Link to="/profile" onClick={closeMenu} className="text-white text-2xl font-semibold">
                    Hesabım
                  </Link>
                  <button
                    onClick={() => {
                      handleSignOut();
                      closeMenu();
                    }}
                    className="text-white text-2xl font-semibold"
                  >
                    Çıkış Yap
                  </button>
                </>
              ) : (
                <Link to="/login" onClick={closeMenu} className="text-white text-2xl font-semibold">
                  Giriş Yap
                </Link>
              )}
            </div>
          </div>
        )}

        {/* Search Modal */}
        <Transition appear show={searchModalOpen} as={Fragment}>
  <Dialog as="div" className="relative z-10" onClose={() => setSearchModalOpen(false)}>
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="fixed inset-0 bg-black bg-opacity-90" />
    </Transition.Child>

    <div className="fixed inset-0 overflow-y-auto">
      <div className="flex min-h-full items-center justify-center p-4 text-center">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Dialog.Panel className="w-full max-w-lg transform overflow-hidden p-6 text-left align-middle transition-all relative">
            
            {/* Close Button */}
            <button 
              className="absolute top-2 right-2 text-gray-400 hover:text-white"
              onClick={() => setSearchModalOpen(false)}
              style={{ top: '0', right: '0', margin: '1rem' }} // Close icon en yukarı sağa taşındı
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>

            {/* Search Input */}
            <div className="flex items-center mt-20 space-x-2 relative">
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                className="w-full pl-2 pr-10 py-2 text-lg text-gray-200 bg-transparent border-b focus:outline-none focus:border-white placeholder-gray-500" // Sadece alt border
              />
              {/* Search Icon */}
              <button
                onClick={handleSearch}
                className="absolute right-2 text-white"
              >
                <MagnifyingGlassIcon className="h-6 w-6 text-gray-400 hover:text-white" />
              </button>
            </div>

          </Dialog.Panel>
        </Transition.Child>
      </div>
    </div>
  </Dialog>
</Transition>




        {/* Arama Çubuğu (Büyük ekranlar için) */}
        <div className="hidden lg:flex lg:justify-center py-4">
          <form className="w-full max-w-lg">
            <div className="relative">
              <input
                type="text"
                placeholder="Ürün ara..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={handleKeyDown}
                className="block w-full pl-10 pr-4 py-2 text-sm text-gray-900 bg-gray-100 rounded-full border border-gray-300 focus:ring-2 focus:ring-primary-600 focus:border-primary-600 focus:outline-none"
              />
              <button
                onClick={handleSearch}
                type="button"
                className="absolute inset-y-0 right-0 flex items-center px-4 bg-blue-600 text-white rounded-r-full"
              >
                Ara
              </button>
            </div>
          </form>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
