import React from 'react';
import Navbar from './Navbar';

const PrivacyPolicy = () => {
  return (
    <>  
    <Navbar/>
     <div className="bg-gray-100 min-h-screen py-10 px-4 sm:px-6 lg:px-8">
    <div className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-lg">
      <h1 className="text-3xl font-bold text-gray-900 mb-6 text-center">
        ELMALI COLLECTIBLES GİZLİLİK SÖZLEŞMESİ
      </h1>

      <div className="space-y-8 text-gray-700">
        <section>
          <h2 className="text-xl font-semibold">1. Veri Sorumlusu</h2>
          <p className="mt-2">
            Bu gizlilik sözleşmesi, Elmalı Collectibles tarafından yönetilmekte olup, Elmalı Collectibles, müşterilerinin kişisel verilerinin gizliliğine ve korunmasına büyük önem vermektedir. Kişisel verileriniz, ilgili mevzuatlar çerçevesinde işlenmekte ve saklanmaktadır.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold">2. Toplanan Kişisel Veriler</h2>
          <p className="mt-2">
            Elmalı Collectibles, kullanıcılarına daha iyi hizmet sunmak amacıyla çeşitli kişisel veriler toplamaktadır. Toplanan veriler şunlardır:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>İsim, soyisim</li>
            <li>E-posta adresi</li>
            <li>Telefon numarası</li>
            <li>Teslimat ve fatura adresleri</li>
            <li>IP adresi ve çerez bilgileri</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold">3. Kişisel Verilerin Toplanma Yöntemleri</h2>
          <p className="mt-2">
            Kişisel veriler, Elmalı Collectibles web sitesi üzerinden sipariş verirken, üyelik oluştururken, form doldururken ve çerezler aracılığıyla otomatik yollarla toplanmaktadır.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold">4. Kişisel Verilerin İşlenme Amaçları</h2>
          <p className="mt-2">
            Elmalı Collectibles tarafından toplanan kişisel veriler, şu amaçlarla işlenmektedir:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>Sipariş süreçlerinin yürütülmesi</li>
            <li>Teslimat ve fatura işlemlerinin gerçekleştirilmesi</li>
            <li>Web sitesinin işlevselliğinin artırılması</li>
            <li>Müşteri ilişkileri yönetimi ve memnuniyetinin sağlanması</li>
            <li>Yasal yükümlülüklerin yerine getirilmesi</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold">5. Kişisel Verilerin Paylaşımı</h2>
          <p className="mt-2">
            Elmalı Collectibles, müşterilerine ait kişisel verileri, yalnızca aşağıdaki durumlarda üçüncü kişilerle paylaşabilir:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>Kargo firmaları ile teslimat süreçlerinin yürütülmesi</li>
            <li>Yasal zorunluluk hallerinde, ilgili kamu kurum ve kuruluşları ile paylaşılması</li>
            <li>Yasal hakların korunması amacıyla avukatlar ve danışmanlarla paylaşılması</li>
          </ul>
        </section>

        <section>
          <h2 className="text-xl font-semibold">6. Çerez Politikası</h2>
          <p className="mt-2">
            Elmalı Collectibles, web sitesinde kullanıcı deneyimini iyileştirmek amacıyla çerezler kullanmaktadır. Çerezler, kullanıcıların site üzerinde yaptığı işlemler hakkında bilgi toplar ve bu bilgileri daha kişiselleştirilmiş bir deneyim sunmak için kullanır. Çerez kullanımına ilişkin detaylar, Çerez Politikası bölümünde açıklanmıştır.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold">7. Kişisel Verilerin Saklanma Süresi</h2>
          <p className="mt-2">
            Toplanan kişisel veriler, işlenme amaçlarına uygun süre boyunca saklanacaktır. Yasal yükümlülüklerin sona ermesi durumunda, kişisel veriler güvenli bir şekilde silinir veya anonim hale getirilir.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold">8. Kişisel Verilerin Güvenliği</h2>
          <p className="mt-2">
            Elmalı Collectibles, kişisel verilerin gizliliğini sağlamak ve yetkisiz erişimi engellemek amacıyla gerekli teknik ve idari önlemleri almaktadır. Bu önlemler, verilerin yetkisiz kişiler tarafından erişilmesini, değiştirilmesini veya ifşa edilmesini önlemeye yöneliktir.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold">9. İlgili Kişinin Hakları</h2>
          <p className="mt-2">
            Kişisel verilerinizle ilgili olarak KVKK’nın 11. maddesi uyarınca şu haklara sahipsiniz:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme</li>
            <li>Kişisel verilerinizin işlenme amacını öğrenme</li>
            <li>Yanlış veya eksik işlenen verilerin düzeltilmesini talep etme</li>
            <li>Kişisel verilerinizin silinmesini veya yok edilmesini talep etme</li>
            <li>Kişisel verilerinizin aktarıldığı üçüncü kişileri bilme ve bu kişilerden düzeltme veya silme isteme</li>
          </ul>
          <p className="mt-2">
            Bu haklarınızı kullanmak için bizimle aşağıdaki iletişim bilgileri aracılığıyla iletişime geçebilirsiniz.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold">10. İletişim Bilgileri</h2>
          <p className="mt-2">
            Her türlü soru, şikayet ve talepleriniz için bizimle iletişime geçebilirsiniz:
          </p>
          <ul className="list-disc list-inside mt-2 space-y-1">
            <li>
              <strong>E-posta:</strong> elmalicollectiblesiletisim@gmail.com
            </li>
            <li>
              <strong>Adres:</strong> Onur Mah. Limon Sok. No: 1/73 Duru Plaza İş Merkezi Balçova / İZMİR
            </li>
            <li>
              <strong>Sosyal Medya:</strong>{' '}
              <a
                href="https://www.instagram.com/elmalicollectibles/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-600 hover:underline"
              >
                https://www.instagram.com/elmalicollectibles/
              </a>
            </li>
          </ul>
        </section>
      </div>
    </div>
  </div> </>
  
  );
};

export default PrivacyPolicy;
