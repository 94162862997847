import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import ProductCard from './ProductCard'; // ProductCard bileşeninin yolunu ekle

function FeaturedPreOrderProducts({ products }) {
  // Hem Featured hem de PreOrder olan ürünleri filtrele
  const filteredProducts = products.filter(
    (product) => product.featured && product.preOrder
  );

  return (
    <div className="pt-12">
      <h2 className="text-2xl font-bold mb-4 text-white text-center">Öne Çıkan Ön Siparişler</h2>

      <div className="mx-auto max-w-7xl px-4 py-3 sm:px-6 sm:py-6 lg:px-8">
        {filteredProducts.length > 0 ? (
          <>
            {/* Masaüstü için swiper (4 ürün gösteren) */}
            <div className="hidden lg:block">
              <Swiper
                modules={[Pagination]}
                spaceBetween={30}
                slidesPerView={4} // Masaüstünde 4 ürün
                pagination={{ clickable: true }}
                style={{
                  paddingBottom: '50px', // Noktaların görünmesi için yeterli alan sağlar
                }}
              >
                {filteredProducts.map((product) => (
                  <SwiperSlide key={product.id}>
                    <ProductCard product={product} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>

            {/* Mobil için swiper (2 ürün gösteren) */}
            <div className="block lg:hidden">
              <Swiper
                modules={[Pagination]}
                spaceBetween={20}
                slidesPerView={2} // Mobilde 2 ürün
                pagination={{ clickable: true }}
                style={{
                  paddingBottom: '50px', // Noktaların görünmesi için yeterli alan sağlar
                }}
              >
                {filteredProducts.map((product) => (
                  <SwiperSlide key={product.id}>
                    <ProductCard product={product} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </>
        ) : (
          <p className="text-center text-white">Ön sipariş ürün bulunamadı.</p>
        )}
      </div>
    </div>
  );
}

export default FeaturedPreOrderProducts;
