import { useState, useEffect } from 'react'
import { UserCircleIcon, MapPinIcon, BellIcon, HeartIcon, ArrowUturnRightIcon, CheckCircleIcon } from '@heroicons/react/24/outline'
import Navbar from '../Navbar'
import { db, auth } from '../firebaseConfig'
import { doc, getDoc, updateDoc } from 'firebase/firestore'
import { onAuthStateChanged, reauthenticateWithCredential, updatePassword, EmailAuthProvider } from 'firebase/auth'
import Swal from 'sweetalert2'

const subNavigation = [
    { name: 'Kişisel Bilgilerim', href: '#', icon: UserCircleIcon, current: true },
    { name: 'Adreslerim', href: '/adres', icon: MapPinIcon, current: false },
    { name: 'Favorilerim', href: '/favorites', icon: HeartIcon, current: false },
    { name: 'Gelince Haber Ver Listem', href: '#', icon: BellIcon, current: false },
    { name: 'Aktif Siparişlerim', href: '#', icon: ArrowUturnRightIcon, current: false },
    { name: 'Geçmiş Siparişlerim', href: '#', icon: CheckCircleIcon, current: false },
]

export default function Profile() {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    gender: '',
    birthdate: '',
    phone: '',
    email: '',
  })
  const [passwords, setPasswords] = useState({ oldPassword: '', newPassword: '' })
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        fetchUserData(user.uid)
      } else {
        setLoading(false)
      }
    })
    return unsubscribe
  }, [])

  const fetchUserData = async (userId) => {
    setLoading(true)
    try {
      const userDoc = await getDoc(doc(db, 'users', userId))
      if (userDoc.exists()) {
        const data = userDoc.data()
        setUserData({
          ...data,
          email: auth.currentUser.email,
        })
      }
    } catch (error) {
      setError('Kullanıcı verileri yüklenirken hata oluştu. Lütfen daha sonra tekrar deneyin.')
      console.error('Veri çekme hatası:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    if (name === 'oldPassword' || name === 'newPassword') {
      setPasswords((prev) => ({ ...prev, [name]: value }))
    } else {
      setUserData((prevData) => ({ ...prevData, [name]: value }))
    }
  }

  const handleSave = async (e) => {
    e.preventDefault()
    setError(null)
    try {
      const userId = auth.currentUser?.uid
      if (userId) {
        const userRef = doc(db, 'users', userId)
        await updateDoc(userRef, userData)
        Swal.fire('Başarılı', 'Bilgiler başarıyla güncellendi!', 'success')
      }
    } catch (error) {
      setError('Bilgiler kaydedilirken hata oluştu. Lütfen daha sonra tekrar deneyin.')
      console.error('Güncelleme hatası:', error)
    }
  }

  const handlePasswordUpdate = async () => {
    setError(null)
    const user = auth.currentUser
    if (user && passwords.oldPassword && passwords.newPassword) {
      const credential = EmailAuthProvider.credential(user.email, passwords.oldPassword)
      try {
        await reauthenticateWithCredential(user, credential)
        await updatePassword(user, passwords.newPassword)
        Swal.fire('Başarılı', 'Şifre başarıyla güncellendi!', 'success')
        setPasswords({ oldPassword: '', newPassword: '' })
      } catch (error) {
        setError('Eski şifre yanlış veya şifre güncelleme hatası. Lütfen tekrar deneyin.')
        console.error('Şifre güncelleme hatası:', error)
      }
    } else {
      setError('Lütfen hem eski hem de yeni şifreyi girin.')
    }
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status" />
          <p className="mt-3 text-gray-600">Veriler yükleniyor...</p>
        </div>
      </div>
    )
  }

  return (
    <>
      <Navbar />
      <div className="h-screen bg-white">
        <main className="mx-auto max-w-7xl pb-10 lg:px-8 lg:py-12">
          <div className="lg:grid lg:grid-cols-12 lg:gap-x-5">
            <aside className="px-2 py-6 sm:px-6 lg:col-span-3 lg:px-0 lg:py-0">
              <nav className="space-y-1">
                {subNavigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    aria-current={item.current ? 'page' : undefined}
                    className={`${
                      item.current ? 'bg-gray-50 text-orange-600' : 'text-gray-900'
                    } group flex items-center rounded-md px-3 py-2 text-sm font-medium`}
                  >
                    <item.icon
                      aria-hidden="true"
                      className={`${
                        item.current ? 'text-orange-500' : 'text-gray-400'
                      } -ml-1 mr-3 h-6 w-6 flex-shrink-0`}
                    />
                    <span className="truncate">{item.name}</span>
                  </a>
                ))}
              </nav>
            </aside>

            <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
              <section aria-labelledby="profile-details-heading">
                {error && <p className="text-red-600 text-sm">{error}</p>}
                <form onSubmit={handleSave}>
                  <div className="shadow sm:overflow-hidden sm:rounded-md">
                    <div className="bg-white px-4 py-6 sm:p-6">
                      <h2 id="profile-details-heading" className="text-lg font-medium text-gray-900">
                        Profil Bilgileri
                      </h2>

                      <div className="mt-6 grid grid-cols-4 gap-6">
                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="firstName" className="block text-sm font-medium text-gray-900">
                            İsim
                          </label>
                          <input
                            id="firstName"
                            name="firstName"
                            type="text"
                            value={userData.firstName}
                            onChange={handleChange}
                            className="mt-2 block w-full rounded-md border px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="lastName" className="block text-sm font-medium text-gray-900">
                            Soyisim
                          </label>
                          <input
                            id="lastName"
                            name="lastName"
                            type="text"
                            value={userData.lastName}
                            onChange={handleChange}
                            className="mt-2 block w-full rounded-md border px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="gender" className="block text-sm font-medium text-gray-900">
                            Cinsiyet
                          </label>
                          <select
                            id="gender"
                            name="gender"
                            value={userData.gender}
                            onChange={handleChange}
                            className="mt-2 block w-full rounded-md border px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300"
                          >
                            <option value="">Seçin</option>
                            <option value="Male">Erkek</option>
                            <option value="Female">Kadın</option>
                            <option value="Other">Diğer</option>
                            <option value="Prefer not to say">Belirtmek istemiyorum</option>
                          </select>
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="birthdate" className="block text-sm font-medium text-gray-900">
                            Doğum Tarihi
                          </label>
                          <input
                            id="birthdate"
                            name="birthdate"
                            type="date"
                            value={userData.birthdate}
                            onChange={handleChange}
                            className="mt-2 block w-full rounded-md border px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="phone" className="block text-sm font-medium text-gray-900">
                            Telefon
                          </label>
                          <input
                            id="phone"
                            name="phone"
                            type="tel"
                            value={userData.phone}
                            onChange={handleChange}
                            className="mt-2 block w-full rounded-md border px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                            E-posta
                          </label>
                          <input
                            id="email"
                            name="email"
                            type="email"
                            value={userData.email}
                            disabled
                            className="mt-2 block w-full rounded-md border px-3 py-1.5 bg-gray-100 text-gray-500 shadow-sm ring-1 ring-gray-300"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-900">
                            Mevcut Şifre
                          </label>
                          <input
                            id="oldPassword"
                            name="oldPassword"
                            type="password"
                            value={passwords.oldPassword}
                            onChange={handleChange}
                            className="mt-2 block w-full rounded-md border px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300"
                          />
                        </div>

                        <div className="col-span-4 sm:col-span-2">
                          <label htmlFor="newPassword" className="block text-sm font-medium text-gray-900">
                            Yeni Şifre
                          </label>
                          <input
                            id="newPassword"
                            name="newPassword"
                            type="password"
                            value={passwords.newPassword}
                            onChange={handleChange}
                            className="mt-2 block w-full rounded-md border px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
                      >
                        Kaydet
                      </button>
                      <button
                        type="button"
                        onClick={handlePasswordUpdate}
                        className="ml-4 inline-flex justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
                      >
                        Şifreyi Güncelle
                      </button>
                    </div>
                  </div>
                </form>
              </section>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
