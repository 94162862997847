import React, { useEffect, useState, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/autoplay'; 
import 'swiper/css/navigation'; 
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; 

const Hero = () => {
  const navigate = useNavigate();
  const [slides, setSlides] = useState([]);
  const swiperRef = useRef(null); 

  useEffect(() => {
    const fetchSliders = async () => {
      const querySnapshot = await getDocs(collection(db, 'Sliders'));
      const slidersData = querySnapshot.docs.map((doc) => doc.data());
      setSlides(slidersData);
    };
    fetchSliders();
  }, []);

  const handlePrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev(); 
    }
  };

  const handleNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext(); 
    }
  };

  return (
    <section className="relative bg-[#191919] text-white md:h-[430px] h-[300px] overflow-hidden"> 
      <Swiper
        modules={[Autoplay]}
        loop={true} 
        autoplay={{ delay: 3000, disableOnInteraction: false }} 
        slidesPerView={1}
        spaceBetween={0}
        className="h-full w-full"
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div
              className="relative w-full h-full cursor-pointer"
              onClick={() => navigate(slide.buttonUrl)}
              style={{
                backgroundImage: `url(${slide.backgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className="absolute inset-0 bg-[#191919] opacity-80"></div>
              <div className="absolute bottom-10 right-10 text-right max-w-md lg:mr-36">
                <h1 className="text-3xl md:text-4xl font-semibold mb-2">
                  {slide.title}
                </h1>
                <p className="text-sm md:text-lg mb-4 bg-red-500 px-4 inline-block">
                  SATIN AL
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Custom navigation buttons */}
      <div
        className="absolute top-1/2 left-4 transform -translate-y-1/2 flex items-center justify-center w-10 h-10 bg-white text-black rounded-full cursor-pointer z-10"
        onClick={handlePrevSlide}
      >
        <span className="text-xl font-bold">{'<'}</span>
      </div>

      <div
        className="absolute top-1/2 right-4 transform -translate-y-1/2 flex items-center justify-center w-10 h-10 bg-white text-black rounded-full cursor-pointer z-10"
        onClick={handleNextSlide}
      >
        <span className="text-xl font-bold">{'>'}</span>
      </div>
    </section>
  );
};

export default Hero;